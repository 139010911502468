/**
 * initial state for global reducer state
 */
export const initialState = {
    user_id: null,
    email: null,
    token: null,
    role: null,
    isAuthenticated: localStorage.getItem('PROMPT-isAuthenticated') | false,
};

/**
   * Store reducer
   * @param {*} state - global states
   * @param {*} action - actions that are passed into the reducer
   */
const authReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
    case 'SET_USER':
        return {
            ...state,
            user_id: payload.user_id,
            email: payload.email,
            jwt: payload.jwt,
            role: payload.role,
            isAuthenticated: true,
        };

    case 'LOGOUT':
        localStorage.removeItem('PROMPT-token');
        localStorage.removeItem('PROMPT-isAuthenticated');
        return {
            ...state,
            user_id: null,
            email: null,
            jwt: null,
            role: null,
            isAuthenticated: false,
        };

    default:
        return state;
    }
};

export default authReducer;
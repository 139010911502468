/**
 * @author Aung Khant Kyaw
 */

import Slider from 'react-slider';
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const ApptContainer = ({ rapid_id, handleSubmit, drug, handleDropdownChange, 
    infusionDose,  setInfusionDose, handleInfusionDoseSliderChange, 
    showAddAptPopup, showPopup, schedule, setSchedule, handleAddSurvey, 
    surveyAdded, handleAddAptBtnClick, appointments,handleAptRowClick, 
    handleAptRowKeyDown, handleTableRowMouseEnter, handleTableRowMouseLeave  }) => {

    return (
        <div className="aptContainer">
        {rapid_id ? (
        <form className="add-new-apt-form" onSubmit={handleSubmit}>
            <h2>Add a new appointment</h2>
            <label htmlFor="drug">Drug:</label>
            <select value={drug} onChange={handleDropdownChange}>
                <option name="Rotigotine">Rotigotine</option>
                <option name="Selegiline">Selegiline</option>
            </select>

            <label htmlFor="infusionDose">Dose rate:</label>
            <div className="infusionDoseDiv">
                {/* <input
                type="number"
                id="infusionDose"
                name="infusionDose"
                placeholder="Enter the infusion dose to be administered in mg"
                value={Number(infusionDose)}
                onChange={(event) => setInfusionDose(Number(event.target.value))}
                disabled={!rapid_id}
                /> */}
                <Slider 
                    value={infusionDose}
                    onChange={handleInfusionDoseSliderChange}
                    min={0}
                    max={5}
                    step={0.05} // Adjust for desired precision
                    valueLabelDisplay="auto"
                    className="customSlider"
                    trackClassName="customSlider-track"
                    thumbClassName="customSlider-thumb"
                    markClassName="customSlider-mark"
                    disabled={showAddAptPopup === true || showPopup === true}
                    renderThumb={(props, state) => (!showAddAptPopup && !showPopup) && <div {...props}><br/><div style={{ marginTop: '8px' }}>{state.valueNow}</div></div>}
                />
            </div>
            <label htmlFor="schedule">Appointment Schedule:</label>
            <DatePicker
                className="customDatePicker"
                value={schedule}
                onChange={setSchedule}
                disabled={!rapid_id}
                format="MM/DD/YYYY HH:mm:ss"
                containerStyle={{
                    width: "100%",
                    height: "10%",
                }}
                plugins={[
                    <TimePicker position="bottom" />,
                    <DatePanel markFocused />
                ]}
            /> 
            <br/><br/>
            <button 
                className="add-apt-btn"
                type="button"
                onClick={handleAddSurvey}
                disabled={surveyAdded}
            >
                {surveyAdded ? 'Survey Added' : 'Add Survey'}
            </button>

            <br/><br/>
            <button 
                className="add-apt-btn"
                type="button"
                onClick={handleAddAptBtnClick}
                disabled={!rapid_id || !drug || infusionDose <= 0 || schedule.length === 0}
            >
                Add Appointment(s)
            </button>
        </form>
        ) : (
            <div>
                <br/><br/>
                <h2>Enter a Rapid ID to add appointments.</h2>
            </div>
        )}

        <div className="tables-container">
            <h2>Scheduled Appointments</h2>
            <div className="appointments-container">
                {appointments.length === 0 ? (
                    <p>No upcoming appointments</p>
                ) : (
                    rapid_id ? (
                        <table className="appointments-table" >
                        <thead>
                            <tr>
                            <th>Scheduled Start Date</th>
                            <th>Scheduled Start Time</th>
                            <th>Drug</th>
                            </tr>
                        </thead>
                        <tbody>
                            {appointments.map((appointment) => (
                            <tr
                                key={appointment._id.$oid}
                                tabIndex="0"
                                role="button"
                                onClick={handleAptRowClick(appointment)}
                                onKeyDown={(event) => handleAptRowKeyDown(event, appointment)}
                                onMouseEnter={handleTableRowMouseEnter}
                                onMouseLeave={handleTableRowMouseLeave}
                            >
                                <td>{new Date(appointment.scheduled_start).toLocaleDateString()}</td>
                                <td>{new Date(appointment.scheduled_start).toLocaleTimeString()}</td>
                                <td>{appointment.drug}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    ) : (
                        <p>Enter a Rapid ID to add appointments.</p>
                    )
                )}
            </div>
        </div>
    </div>
    );
};
export default ApptContainer;
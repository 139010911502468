import { Route, Routes } from 'react-router-dom';
import Body from './body';
import ProtectedRoutes from './ProtectedRoutes';
import Main from "../../pages/Main";
import AddNewID from "../../pages/AddNewID";
import AptView from "../../pages/AptView";
import AptSum from "../../pages/AptSum";
import PatientView from '../../pages/PatientView';
import Admin from '../../pages/Admin';

import Signin from "../../pages/Signin";
import Signup from "../../pages/Signup";
import ForgotPassword from '../../pages/ForgotPassword';
import Home from "../../pages/Home";


const RouteHandlers = () => {
    return (
        <Routes>
            <Route element={<Body />}>

                <Route exact path='/' element={<Home />} />
                <Route exact path='/signin' element={<Signin />} />
                <Route exact path='/signup' element={<Signup />} />
                <Route exact path='/forgotpassword' element={<ForgotPassword />} />

                {/* Protected rourtes for logged in users - This will eventually include role detection*/}
                <Route element={<ProtectedRoutes />}>
                    <Route exact path='/main' element={<Main />} />
                    <Route exact path='/addnewid' element={<AddNewID />} />
                    <Route exact path='/aptview' element={<AptView />} />
                    <Route exact path='/aptsum' element={<AptSum />} />
                    <Route exact path='/patientView' element={<PatientView />} />
                    <Route exact path='/admin' element={<Admin />} />
                </Route>

                {/*  Catch all routes that don't exist */}
                <Route path='*' element={<h1>404 Page Not Found</h1>} />

            </Route>
        </Routes>
    )
}

export default RouteHandlers;
/**
 * @author Aung Khant Kyaw
 */

import React, { useState } from 'react';
import '../styles/PopUp.css';

const ModifyLicenseKey = ({ onClose, handleSubmitLicenseKey, usersObjects, licenseKeyObjects, licenseKey}) => {
  const [isDuplicatedEmailError, setIsDuplicatedEmailError] = useState(false); // State for error message
  const [isDuplicatedLicenseKeyNumError, setIsDuplicatedLicenseKeyNumError] = useState(false); // State for error message
  const [emptyError, setEmptyError] = useState(false); // State for error message
  const [validEmails, setValidEmails] = useState(licenseKey.valid_emails);

  // ------------ For Add question Hover ------------ //
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  const defaultText = '+';
  const hoverText = 'Add user emails';
  // ------------------------------------------------//

  const [formData, setFormData] = useState({
    license_key: licenseKey.license_key,
    owner_organization: licenseKey.owner_organization,
    owner_email: licenseKey.owner_email
  });
  
  const [newEmail, setNewEmail] = useState(''); // State for new email address

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setEmptyError(false);
  };

  const handleAddEmail = () => {
    if (newEmail.trim() !== '' && !checkForDuplicateEmail(newEmail)) { // Check if email is not empty
      setValidEmails([...validEmails, newEmail]);
      setNewEmail(''); // Reset new email input
    }
  };

  const handleRemoveEmail = (index) => {
    const newValidEmails  = [...validEmails];
    newValidEmails .splice(index, 1); // Remove email at specified index
    setValidEmails(newValidEmails );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if any required fields are empty
    if (!formData.license_key || !formData.owner_organization || !formData.owner_email || validEmails.length === 0) {
      setEmptyError(true);
      return;
    }
    if (!isDuplicatedEmailError && !isDuplicatedLicenseKeyNumError) {
      const newLicenseKey = {
        license_key: formData.license_key,
        owner_organization: formData.owner_organization,
        owner_email: formData.owner_email,
        valid_emails: validEmails
      }
      handleSubmitLicenseKey(newLicenseKey, licenseKey._id.$oid);
      onClose();
    }
  };

  const checkForDuplicateEmail = (newEmail) => {
    // Check if email exists in any valid_emails array of licenseKeyObjects
    return licenseKeyObjects.some((licenseObject) => licenseObject.valid_emails.some((existingEmail) => existingEmail === newEmail)) || licenseKeyObjects.some((licenseObject) => licenseObject.owner_email === newEmail) || usersObjects.some((user) => user.email === newEmail);
  };

  const checkForDuplicateLicenseKey = (licenseKeyNum) => {
    // Check if email exists in any valid_emails array of licenseKeyObjects
    return licenseKeyObjects.some((licenseObject) => licenseObject.license_key === licenseKeyNum);
  };

  return (
    <div className="modal-container">
      <div className='modal-overlay'></div>
      <div className='modal-wrapper'>
        <div className='top-wrapper'>
          <div  className='title'>Modify License Key</div>
        </div>
        <div className='info-wrapper'>
          <form className='license-form'>
            <label htmlFor="license_key">License Key:</label>
            <input
              type="text"
              id="license_key"
              name="license_key"
              value={formData.license_key}
              onChange={(e) => {
                handleChange(e)
                checkForDuplicateLicenseKey(e.target.value)
                setIsDuplicatedLicenseKeyNumError(checkForDuplicateLicenseKey(e.target.value))
              }}
              required
            />
            {isDuplicatedLicenseKeyNumError && <p className='error-message'>This license key already exist in database.</p>}
            <label htmlFor="owner_organization">Owner Organization:</label>
            <select value={formData.owner_organization} id="owner_organization" name="owner_organization" onChange={handleChange} >
              <option name="None">None</option>
              <option name="Columbia University">Columbia University</option>
              <option name="Oregon Health and Science University">Oregon Health and Science University</option>
            </select>
            <label htmlFor="owner_email">Owner Email:</label>
            <input
              type="email"
              id="owner_email"
              name="owner_email"
              value={formData.owner_email}
              onChange={(e) => {
                handleChange(e)
                checkForDuplicateEmail(e.target.value)
                setIsDuplicatedEmailError(checkForDuplicateEmail(e.target.value))
              }}
              required
            />
            <h3>Valid Emails:</h3>
            <ul style={{ listStyleType: "none" }}>
              {validEmails.map((email, index) => (
                <li key={index}>
                  {email}                  
                  {index === validEmails.length-1 &&
                    <button style={{margin: "10px"}} onClick={() => {
                      handleRemoveEmail(index)
                    }}>Remove</button>
                  }
                </li>
              ))}
            </ul>

            <label htmlFor="new_email">Add Email:</label>
            <input
              type="email"
              id="new_email"
              name="new_email"
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value)
                checkForDuplicateEmail(e.target.value)
                setIsDuplicatedEmailError(checkForDuplicateEmail(e.target.value))
                setEmptyError(false)
              }}
            />
            {isDuplicatedEmailError && <p className='error-message'>This email already exist in database.</p>}
            <button type="button" onClick={handleAddEmail}>
              Add
            </button>
            <br />
          </form>
        </div>
        {emptyError && <p className='error-message'>Please fill all the fields.</p>}
        <div className='btn-wrapper'>
          <button className='btn' onClick={handleSubmit}>Submit</button>
          <button className='btn' onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};
export default ModifyLicenseKey;
import React, { useState, useEffect } from "react";
import "../styles/AddNewID.css"
import triton_logo from "../images/triton_logo.jpg";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {useNavigate, useLocation, redirect, Navigate } from "react-router-dom";
import axios from "axios";
import Slider from 'react-slider';
import SurveyPopup from '../components/SurveyPopUp';
import useAuth from '../utils/auth/useAuth.js';
import ApptContainer from '../components/ApptContainer'

/**
 * Component to provide rendering and logic for the add new RAPID ID page
 * @component
 * @returns {React.HTMLAttributes}
 */
function AddNewID() {
    // State variables for rapidID, drug, dosage, and schedule
    const { email, user_id, jwt, role, isAuthenticated, logout } = useAuth();
    const [rapid_id, setRapidID] = useState("");
    const [drug, setDrug] = useState("Rotigotine");
    const [infusionDose, setInfusionDose] = useState(0);
    const [schedule, setSchedule] = useState([]);
    const [enteredRapidID, setEnteredRapidID] = useState("");
    const [showAddAptPopup, setShowAddAptPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    console.log("location:", location);
    const [appointments, setAppointments] = useState([]);

    // Survery Pop Up 
    const [showSurveyPopup, setShowSurveyPopup] = useState(false);
    const [survey,  setSurvey] = useState([]);
    const surveyAdded = survey.length > 0;
    const [required, setRequired]  =  useState(false);

    /**
     * UseEffect is ran on page load and every time a state variable is updated. Used here to load appointemnts associated with the chosen RAPID ID if any. 
     */
    useEffect(() => {
        console.log("AddnewID: ", appointments.length)
        // if not isAuthenticated, redirect to signin
        if (!isAuthenticated){
            return <Navigate to={'/signin'} replace='true' />;
        }
        if(rapid_id != ""){
            console.log("in useeffect func");
            if(isLoading){
                const getAptsPerRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/appointments/" + rapid_id;
                axios.get(getAptsPerRapidIDEndpoint,{
                    headers: {
                            Authorization: `Bearer ${jwt}`
                        },
                })
                .then((response) => {
                    // Filter appointments based on scheduled_start in the future
                    const upcomingAppointments = response.data.filter(appointment => {
                        const appointmentDate = new Date(new Date(appointment.scheduled_start).toLocaleString()); // Parse scheduled_start as a Date object
                        return appointmentDate > new Date() && appointment.appointment_status !== "Completed"; // Check if appointment date is after the current date and time
                    });
                    // Sort upcomingAppointments by scheduled_start in ascending order
                    upcomingAppointments.sort((a, b) => {
                        const dateA = new Date(a.scheduled_start);
                        const dateB = new Date(b.scheduled_start);
                        return dateA - dateB; // Sort by comparing timestamps (ascending order)
                    });
                    console.log("Appointment(s) per user after filtering past appointments:", upcomingAppointments)
                    setAppointments(upcomingAppointments);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                });
        }
    }});

    /**
     * Hook to handle when one of the appointment table rows is selected. Loads the appointment view page for the selected appointment. 
     */
    const handleAptRowClick = (appointment) => () => {
    // Implement your logic for handling appointment row click here
        console.log(`Appointment clicked: ${appointment.rapidID} at ${appointment.dateTime}`);
        navigate("/aptview", {
            state: {
                selectedApt: appointment
            }
        })
    };

    /**
     * This function updates the state value when the dosage rate slider is changed
     * @param {Float} newValue 
     */
    const handleInfusionDoseSliderChange = (newValue) => {
        setInfusionDose(newValue);
    }
    
    /**
     * Hook to handle when a row in the appointment table is selcted via keyboard commands
     * @param {React.KeyboardEvent} event 
     * @param {React.HTMLAttributes} appointment 
     */
    const handleAptRowKeyDown = (event, appointment) => {
        if (event.key === "Enter") {
        handleAptRowClick(appointment)();
        }
    };

    /**
     * Hook to handle when the submit appointment button is selected. Brings up the confirm add appointment popup. 
     */
    const handleAddAptBtnClick = () => {
        // Implement your logic for adding a new Rapid ID here
        console.log("Add Apt button clicked");
        console.log("schedule:", schedule)
        setShowAddAptPopup(true);
    };

    /**
     * Hook to handle the hover enter event for table rows
     * @param {React.MouseEvent} e 
     */
    const handleTableRowMouseEnter = (e) => {
        console.log("e:",e);
        e.target.parentElement.style.backgroundColor = "#f5f5f5";
    };

    /**
     * Hook to handle the hover exit event for table rows
     * @param {React.MouseEvent} e 
     */
    const handleTableRowMouseLeave = (e) => {
        e.target.parentElement.style.backgroundColor = "white";
    };

    /**
     * Hook to handle when the back button is selected. Brings up the confirm exit menu popup
     */
    const handleCancelClick = () => {
        setShowPopup(true);
    }

    /**
     * Hook to handle when the user confirms exiting the form in the confirm exit menu popup. Brings the user to their previous page. 
     */
    const handlePopupDelete = () => {
        navigate(-1);
    }

    /**
     * Hook to handle when the user clicks the button to verify the entered RAPID ID. Makes a call to the API to confirm that the entered ID is available and valid. 
     */
    const handleVerifyIDClick = () => {
        console.log("Entered ID:", enteredRapidID);
        const checkValidRapidIdEndpoint = String(process.env.REACT_APP_API_URI) + "/check-rapid-id/" + enteredRapidID;
        axios.get(checkValidRapidIdEndpoint,{
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        })
        .then((response) => {
            console.log(response);
            setRapidID(enteredRapidID);
            const createRapidIdEndpoint = String(process.env.REACT_APP_API_URI) + "/rapid_id";
            axios.post(createRapidIdEndpoint, {
                rapid_id: enteredRapidID,
                user_assigned_to: user_id
            },{
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            }).then((inner_response) => {
                console.log(inner_response);
                const deleteValidRapidIdEndpoint = String(process.env.REACT_APP_API_URI) + "/valid_rapid_id/" + response.data._id.$oid;
                axios.delete(deleteValidRapidIdEndpoint,{
                    headers: {
                            Authorization: `Bearer ${jwt}`
                        },
                }).then((inner_inner_response) => {
                    console.log(inner_inner_response);
                }, (error) => {
                    console.log(error);
                })
            }, (error) => {
                console.log(error);
            });
        })
        .catch(error => {
            console.error(error);
            console.log("Invalid rapid id");
            setShowErrorPopup(true);
        })
    }
    
    /**
     * Hook to handle when the user selects the submit button in the menu to confirm that they are done adding appointments for the user. Returns them to the "main" menu. 
     * @param {React.ChangeEvent} event 
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("Rapid ID:", rapid_id);
        console.log("Appointments:", appointments);

        navigate("/main");
    };

    /**
     * Hook to handle closing popups when the cancel button is selcted on them. 
     */
    const handlePopupCancel = () => {
        setShowPopup(false);
        setShowErrorPopup(false);
    };

    /**
     * Hook to handle closing the add appointment popup when it is cancelled. 
     */
    const handleAddAptPopupCancel = () => {
        setShowAddAptPopup(false);
    }

    /**
     * Hook to call API to a new appointment(s) for the current RAPID ID when the user accepts the submit popup 
     */
    const handleAddAptPopupClick = () => {
        console.log("Rapid ID:", rapid_id);
        console.log("Entered Drug:", drug);
        console.log("Entered Infusion Dose :", infusionDose);
        console.log("Entered Scheduled Date:", schedule[0].toString());
        console.log("Email:", email);
        console.log("RequiredSurvey", survey);
        console.log("Survey", survey);
        setShowAddAptPopup(false);

        const addAptEndpoint = String(process.env.REACT_APP_API_URI) + "/appointment";
        for (var date in schedule){
            const selectedDate = new Date(schedule[date].toString());
            const utcDate = selectedDate.toISOString();;
            axios.post(addAptEndpoint, {
                rapid_id_assigned_to: rapid_id,
                user_assigned_to: user_id,
                drug: drug,
                infusion_dose: infusionDose,
                step_count:0,
                step_count_log:[],
                time_increment:0,
                scheduled_start: utcDate,
                infusion_start_time: "N/A",
                amt_administered: 0,
                appointment_status: "Not Started",
                pump_status: false,
                required_survey: required,
                surveys: survey
            },{
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            }).then((response) => {
                console.log(response);
                setDrug("Rotigotine");
                setInfusionDose("0");
                setSchedule([]);
                setIsLoading(true);
            }, (error) => {
                console.log(error);
                setDrug("Rotigotine");
                setInfusionDose("0");
                setSchedule([]);
            });
        }
        setDrug("Rotigotine");
        setInfusionDose("0");
        setSchedule([]);
        setIsLoading(true);
    }

    /**
     * Hook to handle updating the drug state variable when the drug dropdown is changed. 
     * @param {React.ChangeEvent} event 
     */
    const handleDropdownChange = (event) => {
        event.preventDefault();
        setDrug(event.target.value);
    }

    const handleAddSurvey = () => {
        setShowSurveyPopup(!showSurveyPopup);
    };

    const handleSurveyQuestion = (questions,  isRequired) => {
        setSurvey(questions);
        setRequired(isRequired);
        // setShowSurveyPopup(false);
    }

    /**
     * Rendering logic. 
     */
    return (
        <div className="add-new-id-page">
        <header className="header3">
            <button onClick={handleCancelClick}>
                Back
            </button>
            <span className="tab"></span>
            <div className="log2">
            <img src={triton_logo} alt="Triton Logo" />
            </div>
        </header>
        <br/>
        <h2>Add a new RAPID ID</h2>
        <form className="add-new-id-form" onSubmit={handleSubmit}>
            <label htmlFor="rapid_id">RAPID ID:</label>
            <div className="rapidIdDiv">
                <input
                type="text"
                id="rapid_id"
                name="rapid_id"
                placeholder="Enter the new RAPID ID"
                value={enteredRapidID}
                onChange={(event) => setEnteredRapidID(event.target.value)}
                disabled={rapid_id}
                />
                <button 
                    className="verifyIDBtn" 
                    type="button" 
                    onClick={handleVerifyIDClick} 
                    disabled={rapid_id}
                >
                    Verify ID
                </button>
            </div>
            <ApptContainer rapid_id={rapid_id} handleSubmit={handleSubmit} 
                drug={drug} handleDropdownChange={handleDropdownChange} 
                infusionDose={infusionDose} setInfusionDose={setInfusionDose} 
                handleInfusionDoseSliderChange={handleInfusionDoseSliderChange}
                showAddAptPopup={showAddAptPopup} showPopup={showPopup} schedule={schedule} setSchedule={setSchedule}
                handleAddSurvey={handleAddSurvey} surveyAdded={surveyAdded} handleAddAptBtnClick={handleAddAptBtnClick}
                appointments={appointments} handleAptRowClick={handleAptRowClick} handleAptRowKeyDown={handleAptRowKeyDown}
                handleTableRowMouseEnter={handleTableRowMouseEnter} handleTableRowMouseLeave={handleTableRowMouseLeave}
            />
            <button type="submit" className="submitBtn" disabled={!rapid_id}>Submit</button>
        </form>
        {showPopup && (
            <div className="popup">
                <div className="content">
                    <h3>Are you sure?</h3>
                    <p>
                        If you navigate away from this page you will not create RAPID-ID User: {rapid_id}.
                    </p>
                    <button type="button" onClick={handlePopupDelete}>Continue</button>
                    <button type="button" onClick={handlePopupCancel}>Cancel</button>
                </div>
            </div>
        )}

        {showErrorPopup && (
            <div className="popup">
                <div className="content">
                    <h3>Invalid RAPID ID</h3>
                    <p>
                        The RAPID ID you entered was invalid, please check and try again: {enteredRapidID}.
                    </p>
                    <button type="button" onClick={handlePopupCancel}>Close</button>
                </div>
            </div>
        )}

        {showAddAptPopup && (
                <div className="popup">
                <div className="content">
                    <h3>Add appointment?</h3>
                    <p>
                        Please confirm you would like to add a new appointment for RAPID User: {rapid_id} 
                        with the following details:
                    </p>
                    <p>
                        Drug: {drug}
                    </p>
                    <p>
                        Dose rate: {infusionDose}
                    </p>
                    <p>
                        Appointment Schedule: {
                                schedule.map(apt => (
                                    <p style={{fontSize: "25px"}}>{apt.toString()}</p>
                                )
                            )} 
                    </p>
                    <button type="button" onClick={handleAddAptPopupClick}>Add</button>
                    <button type="button" onClick={handleAddAptPopupCancel}>Cancel</button>
                </div>
            </div>
            )}
        {showSurveyPopup && <SurveyPopup onClose={() => setShowSurveyPopup(false)} handleSurveySubmit={(surveyQuestion, required) => handleSurveyQuestion(surveyQuestion, required)} />}
        </div>
    );
}

export default AddNewID;
import { createContext, useReducer, useEffect  } from 'react';
import authReducer, { initialState } from './authReducer';
import {Navigate} from "react-router-dom";
import axios from "axios";
/**
 * This component helps manage User states globally
 * @function
 * @version 0.1
 * @see Functions/App
 * @see createContext
 * @see useState
 * @see useEffect
 * @see Modules/Auth
 */
export const AuthContext = createContext({ initialState });

/**
 * This component provides a UserProvider which used in index.js to wrapper the whole application to
 * distribute global user states
 * @param children - Every props passed in
 */
export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        checkForToken();
    }, []);

    /**
    * Load user from database using PROMPT-token retrieved from localStorage.
    * Dispatches "SET_USER" action in useReducer to store user in global state
    */
    const loadUser = async () => {
        checkForToken()
    };

    /**
     * Log user out.
     * Dispatches "USER_LOGOUT" action in useReducer to remove "J4I-token" and "J4I-isAuthenticated" from localStorage, set state to initalState
     */
    const logout = () => {
        dispatch({
        type: 'LOGOUT',
        });
    };

    // ************************* Helper Functions ************************** //

    // Check the token expiration, 
    // If it is expired, remove the token and logout
    // if it is not expired, do nth as token with id, email, jwt-token, and role is already saved
    const checkForToken = async () => {
        const token = JSON.parse(localStorage.getItem('PROMPT-token'));
        if (token) {
            try {
                const checkTokenExpirationEndpoint = String(process.env.REACT_APP_API_URI) + "/check-token-expiration";
                await axios.get(checkTokenExpirationEndpoint,{
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }).then((response) => {
                    dispatch({ type: 'SET_USER', payload: token });
                    return;
                })
                .catch(error => {
                    console.error(error);
                    dispatch({ type: 'LOGOUT' });
                    return false;
                });;
            } catch (error) {
                console.error(error);
                dispatch({ type: 'LOGOUT' });
                <Navigate to={'/signin'} replace='true' />;
                return false;
            }
        }
    };

    /**
     * Auth Context Provider exports values of
     * @user
     * @isAuthentication
     * @loadUser - Function
     * @logout - Function
     * @return Context Provider
     */
    return (
        <AuthContext.Provider
        value={{
            user_id: state.user_id,
            email: state.email,
            jwt: state.jwt,
            role: state.role,
            isAuthenticated: state.isAuthenticated,
            loadUser,
            logout,
        }}
        >
        {children}
        </AuthContext.Provider>
    );
};
/**
 * @author Aung Khant Kyaw
 */

import React, { useState } from 'react';
import '../styles/PopUp.css';

const DeleteLicenseKey = ({ onClose, handleSubmit , licenseKey}) => {

  const submit = () => {
    handleSubmit(licenseKey._id.$oid);
    onClose();
  }

  return (
    <div className="modal-container">
      <div className='modal-overlay'></div>
      <div className='modal-wrapper-licenseKey'>
        <div className='top-wrapper'>
          <div  className='title-delete'>Are you sure you want to delete the following License Key?</div>
        </div>
        <div className='info-wrapper'>
          <p style={{ color: 'red', textAlign: 'center' }}>License Key: {licenseKey.license_key}</p>
          <p style={{ color: 'red', textAlign: 'center' }}>Owner Organization: {licenseKey.owner_organization}</p>
          <p style={{ color: 'red', textAlign: 'center' }}>Owner Email: {licenseKey.owner_email}</p>
          {licenseKey.valid_emails.map((email, index) => (
                <p style={{ color: 'red', textAlign: 'center' }}>Valid Email: {email}</p>
              ))}
        </div>
        <div className='btn-wrapper'>
          <button className='btn' onClick={submit}>Delete</button>
          <button className='btn' onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
export default DeleteLicenseKey;
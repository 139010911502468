import React, { useContext, useState, useEffect } from "react";
import '../styles/Main.css';
import triton_logo from '../images/triton_logo.jpg'
import { redirect, Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import useAuth from '../utils/auth/useAuth.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import AddNewValidRapidID from "../components/AddNewValidRapidID.jsx";
import DeleteValidRapidID from "../components/DeleteValidRapidID.jsx";
import ModifyValidRapidID from "../components/ModifyValidRapidID.jsx";
import AddNewLicenseKey from "../components/AddNewLicenseKey.jsx";
import DeleteLicenseKey from "../components/DeleteLicenseKey.jsx";
import ModifyLicenseKey from "../components/ModifyLicenseKey.jsx";

/**
 * This component provides the rendering and logic for the "main menu" view for the user
 * @component
 * @returns {React.HTMLAttributes}
 */
function Main() { 

    const { email, user_id, jwt, role, isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();
    const [validRapidIDs, setvalidRapidIDs] = useState([]);
    const [rapidIDs, setRapidIDs] = useState([]);
    const [licenseKeys, setlicenseKeys] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedValidRapidID, setSelectedValidRapidID] = useState();
    const [selectedValidRapidID_OID, setSelectedValidRapidID_OID] = useState();
    const [selectedLicenseKey, setSelectedLicenseKey] = useState();

    // add new valid rapid id Pop Up 
    const [showAddNewValidID, setShowAddNewValidID] = useState(false);
    const [showDeleteValidID, setShowDeleteValidID] = useState(false);
    const [showModifyValidID, setShowModifyValidID] = useState(false);
    const [showAddNewLicenseKey, setShowAddNewLicenseKey] = useState(false);
    const [showDeleteLicenseKey, setShowDeleteLicenseKey] = useState(false);
    const [showModifyLicenseKey, setShowModifyLicenseKey] = useState(false);

    // Button Hover changes
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredLK, setIsHoveredLK] = useState(false);
    
    /**
     * This function refreshes the page whenever it is first loaded or one of the state variables is updated. Here it is used to 
     * get the RAPID IDs and Appointments associated with the user. 
     */
    useEffect(() => {
        // if not isAuthenticated or is not admin role user, redirect to signin
        if (!isAuthenticated || role !== "admin"){
            return <Navigate to={'/signin'} replace='true' />;
        }
        // fetch data
        fetchData()
    }, []);

    // Method to get fetch appointment details based on the selected apt ID
    const fetchData = async () => {
        try {
             // get all valid rapid IDs
            const getValidRapidIdsEndpoint = String(process.env.REACT_APP_API_URI) + "/valid_rapid_ids";
            axios.get(getValidRapidIdsEndpoint, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            })
            .then((response) => {
                console.log("Valid Rapid IDs: ", response);
                setvalidRapidIDs(response.data);
            })
            .catch(error => {
                console.error(error);
            });
            // get all livense keys
            const getLicenseKeysEndpoint = String(process.env.REACT_APP_API_URI) + "/license_keys";
            axios.get(getLicenseKeysEndpoint, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            })
            .then((response) => {
                console.log("License Keys: ", response);
                setlicenseKeys(response.data);
            })
            .catch(error => {
                console.error(error);
            });
            // get all rapid IDs
            const getRapidIDsEndpoint = String(process.env.REACT_APP_API_URI) + "/rapid_ids";
            axios.get(getRapidIDsEndpoint, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            })
            .then((response) => {
                console.log("Rapid IDs: ", response);
                setRapidIDs(response.data);
            })
            .catch(error => {
                console.error(error);
            });
            // get all users
            const getUsersEndpoint = String(process.env.REACT_APP_API_URI) + "/users";
            axios.get(getUsersEndpoint, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            })
            .then((response) => {
                console.log("Users: ", response);
                setUsers(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        } catch (error) {
            console.log(error);
        }
    };

    
    /**
    * Hook to handle when the user selcts the logout button. Resets the state and returns them to the landing page. 
    */
    const handleLogoutClick = () => {
        const logoutEndpoint = String(process.env.REACT_APP_API_URI) + "/logout/" + String(user_id);
        axios.delete(logoutEndpoint, {
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then(async (response) => {
            await logout();
            navigate("/")
        }, (error) => {
            console.log(error);
        } 
        )
    }

    // ------------ Button click hanlders for Valid rapid ids ------------- //
    /**
     * Hook to handle when one of the RAPID ID table rows is selected, loads the correct patient view. 
     * @param {React.HTMLAttributes} id 
     * @returns {null}
     */
    const handleValidIDClick = (id) => {
        // Implement your logic for handling ID row click here
    };

    /**
     * Hook to handle when add new valid rapid id is clicked
     * @returns {null}
     */
    const handleValidRapidIdDeleteClick = (id) => {
        // Implement your logic for handling ID row click here
    };

    /**
     * Hook to handle when add new valid rapid id is clicked
     * @returns {null}
     */
    const handleValidRapidIdModifyClick = (id) => {
        // Implement your logic for handling ID row click here
    };

    // ------------ For Add new Valid Rapid ID api calls ------------ //
    const handleSubmitNewValidRapidID = (rapidID) => {
        const addValidRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/valid_rapid_id";
        axios.post(addValidRapidIDEndpoint, {
            valid_rapid_id: rapidID,
        },{
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            fetchData();
        }, (error) => {
            console.log(error);
        });
    };

    const handleSubmitDeleteValidRapidID = (rapidID_OID) => {
        const deleteValidRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/valid_rapid_id/" + rapidID_OID;
        axios.delete(deleteValidRapidIDEndpoint,
        {
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            fetchData();
        }, (error) => {
            console.log(error);
        });
    };

    const handleSubmitModifyValidRapidID = (inputText, rapidID_OID) => {
        const putValidRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/valid_rapid_id/" + rapidID_OID;
        axios.put(putValidRapidIDEndpoint, {
            valid_rapid_id: inputText,
        },{
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            fetchData();
        }, (error) => {
            console.log(error);
        });
    };

    const handleSubmitAddNewLicenseKey = (formData) => {
        const addAddValidRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/license_key";
        axios.post(addAddValidRapidIDEndpoint, {
            license_key: formData.license_key,
            owner_organization: formData.owner_organization,
            owner_email: formData.owner_email,
            valid_emails: formData.valid_emails,
        },{
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            fetchData();
        }, (error) => {
            console.log(error);
        });
    };

    const handleSubmitModifyLicenseKey = (newLicenseKey, licenseKey_OID) => {
        const putLicenseKeyEndpoint = String(process.env.REACT_APP_API_URI) + "/license_key/" + licenseKey_OID;
        axios.put(putLicenseKeyEndpoint, {
            license_key: newLicenseKey.license_key,
            owner_organization: newLicenseKey.owner_organization,
            owner_email: newLicenseKey.owner_email,
            valid_emails: newLicenseKey.valid_emails,
        },{
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            fetchData();
        }, (error) => {
            console.log(error);
        });
    };

    const handleSubmitDeleteLicenseKey = (licenseKey_OID) => {
        const deleteLicenseKeyEndpoint = String(process.env.REACT_APP_API_URI) + "/license_key/" + licenseKey_OID;
        axios.delete(deleteLicenseKeyEndpoint,
        {
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            fetchData();
        }, (error) => {
            console.log(error);
        });
    };
    // ------------------------------------------------//

    /**
     * Rendering logic for component
     */
    return (
        <div className="dashboard-container">
            <div className="header-container">
                <button className="add-rapid-btn" onClick={handleLogoutClick}>
                    Log Out
                </button>
                <span className="welcome-message">Welcome to Admin Page, {email}!</span>
            </div>
            <div className="content-container">
                <div className="admin-tables-container">
                    <div className="rapid-ids-container">
                        <div className="valid-rapid-id-container">
                            <h2>Valid Rapid IDs</h2>
                            <button className="add-rapid-btn" onMouseEnter={() => setIsHovered(!isHovered)} onMouseLeave={() => setIsHovered(!isHovered)} onClick={() => setShowAddNewValidID(!showAddNewValidID)}>
                            {isHovered ? "Add new Valid Rapid ID" : "+"}
                            </button>
                        </div>
                        {validRapidIDs.length === 0 ? (
                            <p>No Valid Rapid ID</p>
                        ) : (
                            <table className="rapid-ids-table">
                                <thead>
                                    <tr>
                                        <th>Unique ID</th>
                                        <th>Valid Rapid IDs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validRapidIDs.map((valid_rapid_ID) => (
                                    <tr
                                        key={valid_rapid_ID.valid_rapid_id}
                                        tabIndex="0"
                                        role="button"
                                        onClick={handleValidIDClick(valid_rapid_ID._id.$oid)}
                                    >
                                        <td>{valid_rapid_ID._id.$oid}</td>
                                        <td>{valid_rapid_ID.valid_rapid_id}</td>
                                        <td>
                                            <button onClick={() => {
                                                setShowDeleteValidID(!showDeleteLicenseKey)
                                                setSelectedValidRapidID(valid_rapid_ID.valid_rapid_id)
                                                setSelectedValidRapidID_OID(valid_rapid_ID._id.$oid)
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                setShowModifyValidID(!showModifyValidID)
                                                setSelectedValidRapidID(valid_rapid_ID.valid_rapid_id)
                                                setSelectedValidRapidID_OID(valid_rapid_ID._id.$oid)
                                            }}>
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </button>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div className="appointments-container">
                        <div className="valid-rapid-id-container">
                            <h2>License Keys</h2>
                            <button className="add-rapid-btn" onMouseEnter={() =>  setIsHoveredLK(!isHoveredLK)} onMouseLeave={() =>  setIsHoveredLK(!isHoveredLK)} onClick={() => setShowAddNewLicenseKey(!showAddNewLicenseKey)}>
                                {isHoveredLK ? "Add License Key" : "+"}
                            </button>
                        </div>
                        {licenseKeys.length === 0 ? (
                            <p>No License Key</p>
                        ) : (
                            <table className="rapid-ids-table">
                                <thead>
                                    <tr>
                                        <th>Valid Rapid IDs</th>    
                                        <th>License Key</th>
                                        <th>Owner Organization</th>
                                        <th>Owner Email</th>
                                        <th>Valid Emails</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {licenseKeys.map((licenseKey) => (
                                    <tr
                                        key={licenseKey.license_key }
                                        tabIndex="0"
                                        role="button"
                                        onClick={handleValidIDClick(licenseKey._id.$oid)}
                                    >
                                        <td>{licenseKey._id.$oid}</td>
                                        <td>{licenseKey.license_key}</td>
                                        <td>{licenseKey.owner_organization}</td>
                                        <td>{licenseKey.owner_email}</td>
                                        <td>
                                            <ul style={{ listStyleType: "none" }}>
                                            {licenseKey.valid_emails.map((email) => (
                                                <li key={email}>{email}</li>
                                            ))}
                                            </ul>
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                setShowDeleteLicenseKey(!showDeleteLicenseKey)
                                                setSelectedLicenseKey(licenseKey)
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                setShowModifyLicenseKey(!showModifyLicenseKey)
                                                setSelectedLicenseKey(licenseKey)
                                            }}>
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </button>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            <div className="footer-container">
                <img src={triton_logo} alt="Triton Logo" />
            </div>
        {showAddNewValidID && <AddNewValidRapidID onClose={() => setShowAddNewValidID(!showAddNewValidID)} handleSubmit={(inputText) => handleSubmitNewValidRapidID(inputText)} 
            rapidIdObjects={rapidIDs} validRapidIdObjects={validRapidIDs} />}
        {showDeleteValidID && <DeleteValidRapidID onClose={() => setShowDeleteValidID(!showDeleteValidID)} handleSubmit={(rapidID_oid) => handleSubmitDeleteValidRapidID(rapidID_oid)} 
            rapidID={selectedValidRapidID} rapidID_OID={selectedValidRapidID_OID}/>}
        {showModifyValidID && <ModifyValidRapidID onClose={() => setShowModifyValidID(!showModifyValidID)} handleSubmit={(inputText, rapidID_oid) => handleSubmitModifyValidRapidID(inputText, rapidID_oid)} 
            rapidID_OID={selectedValidRapidID_OID} rapidIdObjects={rapidIDs} validRapidIdObjects={validRapidIDs} />}
        {showAddNewLicenseKey && <AddNewLicenseKey onClose={() => setShowAddNewLicenseKey(!showAddNewLicenseKey)} handleSubmitLicenseKey={(licenseKeyForm) => handleSubmitAddNewLicenseKey(licenseKeyForm)}
            usersObjects={users} licenseKeyObjects={licenseKeys}/>}
        {showDeleteLicenseKey && <DeleteLicenseKey onClose={() => setShowDeleteLicenseKey(!showDeleteLicenseKey)} handleSubmit={(licenseKey_oid) => handleSubmitDeleteLicenseKey(licenseKey_oid)} 
            licenseKey={selectedLicenseKey}/>}
        {showModifyLicenseKey && <ModifyLicenseKey onClose={() => setShowModifyLicenseKey(!showModifyLicenseKey)} handleSubmitLicenseKey={(newLicenseKey, licenseKey_oid) => handleSubmitModifyLicenseKey(newLicenseKey, licenseKey_oid)} 
            usersObjects={users} licenseKeyObjects={licenseKeys} licenseKey={selectedLicenseKey}/>}
        </div>
    );
}
export default Main;
import React from 'react';
import '../styles/AptView.css';

const SurveyAnswer = ({ question, answerType, answerOptions, answer }) => {
    const options = answerOptions.split(',');
    switch (answerType) {
        case 'text':
            return (
                <div className='survey'>
                    <strong>{question}</strong>
                    <p style={{ 
                            fontWeight: 'bold',
                            color: 'green',
                        }}>{answer}</p>
                </div>
            );
        default:
            return (
                <div className='survey'>
                    <strong>{question}</strong>
                    <ul style={{ listStyleType: 'none' }}>
                        {options.map(option => (
                        <li key={option} style={{ 
                            fontWeight: answer.includes(option) ? 'bold' : 'normal',
                            color: answer.includes(option) ? 'green' : 'red',
                        }}>
                            {option}
                        </li>
                        ))}
                    </ul>
                    <br />
                </div>
            );
    }
    };

export default SurveyAnswer;
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteHandlers from './utils/routing/RouteHandlers';
import useAuth from './utils/auth/useAuth.js';
import { useEffect } from 'react';

/**
 * Provides the HTML rendering of the React application and sets the routes to each component. 
 * @returns The HTML rendering of the entire React appliction
 */
const App = () =>  {
  const { loadUser } = useAuth();

  useEffect(() => {
    (async () => {
      await loadUser();
    })();
  }, []);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<RouteHandlers />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;

import React, { useState, useEffect } from 'react';
import triton_logo from '../images/triton_logo.jpg';
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import SurveyAnswerOptions from '../components/SurveyAnswerWithOptions.jsx';
import SurveyQuestionAndAnswer from '../components/SurveyQuestionAndAnswer.jsx';
import axios from "axios";
import '../styles/AptView.css';
import useAuth from '../utils/auth/useAuth.js';

/**
 * This component provides the rendering and logic for the appointment view of the application.
 * @component
 * @returns {React.HTMLAttributes}
 */
const AptSum = () => {
    const { email, user_id, jwt, role, isAuthenticated, logout } = useAuth();
    const location = useLocation();
    const selectedApt = location.state.selectedApt;
    const navigate = useNavigate();
    console.log(selectedApt.pump_status);

    // Add state variables for each input
    const [isLoading, setIsLoading] = useState(true);
    const [currentApptLoading, setCurrentApptLoading] = useState(true);
    const [currentAppt, setCurrentAppt] = useState();
    const [pumpStatus, setPumpStatus] = useState(selectedApt.pump_status? "Connected": "Disconnected"); // Initial pump status is off
    const [aptStatus, setAptStatus] = useState(selectedApt.appointment_status);
    const [monitorStatus, setMonitorStatus] = useState("Disconnected"); // Initial monitor status is off
    // other helper state variables 
    const [isSurveyChecked, setIsSurveyChecked] = useState(false);

    /**
     * UseEffect function runs on page load and every time a state variable is updated, Used here to fetch updated to the vital signs. 
     */
    useEffect(() => {
        // if not isAuthenticated, redirect to signin
        if (!isAuthenticated){
            return <Navigate to={'/signin'} replace='true' />;
        }
        console.log("Apt View: useEffect: Getting Current Appointment Data");
        fetchData(); 
        setIsLoading(false);
    }, []);

    // ********************** Other Helper Methods ********************* //

    // Method to get fetch appointment details based on the selected apt ID
    const fetchData = async () => {
        const addAptEndpoint = String(process.env.REACT_APP_API_URI) + "/appointment/" + selectedApt._id.$oid;
        try {
            const response = await axios.get(addAptEndpoint, {
                headers: {
                Authorization: `Bearer ${jwt}`
                }
        });
        console.log(response.data);
        setCurrentAppt(response.data);
        setAptStatus(response.data.appointment_status);
        setPumpStatus(response.data.pump_status ? "Connected" : "Disconnected");
        setCurrentApptLoading(false);
        } catch (error) {
        console.log(error);
        }
    };

    const handleDownload = async () => {
        if (!currentAppt) {
            // Inform the user that data is not ready or unavailable
            console.warn('Appointment data not available for download yet.');
            return;
        }

        const filename = `PROMPT-appointment-${currentAppt._id.$oid}.json`; // Customize filename if needed
        const data = JSON.stringify(currentAppt, null, 2); // Format with indentation for readability
        const blob = new Blob([data], { type: 'application/json' });

        if (window.navigator.webkitURL) { // For WebKit browsers
            const link = document.createElement('a');
            link.href = window.navigator.webkitURL.createObjectURL(blob);
            link.download = filename;
            link.click();
            // Release the object URL after clicking (for memory management)
            setTimeout(() => {
            window.navigator.webkitURL.revokeObjectURL(link.href);
            }, 100);
        } else { // For non-WebKit browsers
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        }
    };

    const handleSurveyChange = (event) => {
        setIsSurveyChecked(event.target.checked);
    };

    /**
     * Rendering logic
     */
    if (isLoading || currentApptLoading) {
        console.log("loading...");
        return <p>Loading...</p>;
    } else {
        return (
            <div className="menu-screen">
                <div className="header4">
                    <div className="header-content">
                        <button className="back-home-button" 
                            disabled={aptStatus==="In Progress"}
                            onClick={() => 
                                navigate('/main')
                            }>
                            Back
                        </button>
                        <div className="logo-container">
                            <img src={triton_logo} alt="Triton Logo" class="logo"/>
                        </div>
                    </div>
                </div>
                <main className="main">
                    <div className="left-column">
                        <div className='leftColumnHeader'>
                            <h2>Appointment Details</h2>
                            <button onClick={handleDownload}>Download as JSON </button>
                        </div>
                        <div className='InfoPanel'>
                            <table className="aptInfoTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label htmlFor="rapid-id">RAPID ID:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.rapid_id_assigned_to}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="aptStatus">Appointment Status:</label>
                                        </td>
                                        <td>
                                            <label>{aptStatus}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                        </td>
                                        <td>
                                            <label>{new Date(currentAppt.scheduled_start).toLocaleString()}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="infusion-start-time">Infusion Start Time: </label>
                                        </td>
                                        <td>
                                            <label>{new Date(currentAppt.infusion_start_time).toLocaleString()}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="timeIncrement">Time Increment:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.time_increment} </label>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <label htmlFor="drug">Drug:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.drug}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="pump-status">Pump Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[pumpStatus]   
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="monitor-status">Monitor Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Warning':
                                                        <div className="warningStatusIndicator">
                                                            Warning
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[monitorStatus]   
                                            }
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <label htmlFor="infusionDose">Dose rate:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.infusion_dose}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="stepCount">Step Count:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.step_count} </label>
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="amt-administered">Drug Amount Administered:</label>
                                        </td>
                                        <td>
                                            <div className="amtAdministeredDiv">
                                                <label>{currentAppt.amt_administered} mg</label>
                                                <span class="tab" />
                                            </div>
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="Current-Step-Count">Current Step Count:</label>
                                        </td>
                                        <td>
                                            <label>{lastStep.time} - {lastStep.step_count}</label>
                                        </td>
                                        <td>
                                            <label>Step Count Log:</label>
                                        </td>
                                        <td>
                                            <div>
                                                {currentAppt.step_count_log.map((step, index) => (
                                                    <div key={index}>
                                                        {new Date(step.time).toLocaleString()} - {step.step_count}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="right-column">
                        <div className='rightColumnHeader'>
                            <h2>{currentAppt.surveys.length !== 0 ? 'Appointment Survey' : 'There is no survey for this appointment.'}</h2>
                            <div className='surveyCheckBox'>
                                <input type="checkbox" checked={isSurveyChecked} onChange={handleSurveyChange} />
                                <label>Show All Answer Options</label>
                            </div>
                        </div>
                        {currentAppt.surveys.length !== 0 && currentAppt.surveys.map((survey, index) => (
                            isSurveyChecked ? (
                                <SurveyAnswerOptions key={index} question={survey.question} answerType={survey.answer_type} answerOptions={survey.answer_options} answer={survey.answer} />   
                            ) : (
                                <SurveyQuestionAndAnswer key={index} question={survey.question} answerType={survey.answer_type} answerOptions={survey.answer_options} answer={survey.answer} />   
                            )
                        ))}
                    </div>
                </main>
            </div>
        );
    }

};

export default AptSum;
/**
 * @author Aung Khant Kyaw
 */

import React, { useState } from 'react';
import '../styles/PopUp.css';

const DeleteValidRapidID = ({ onClose, handleSubmit , rapidID, rapidID_OID}) => {

  const submit = () => {
    handleSubmit(rapidID_OID);
    onClose();
  }

  return (
    <div className="modal-container">
      <div className='modal-overlay'></div>
      <div className='modal-wrapper-validRapidID'>
        <div className='top-wrapper'>
          <div  className='title-delete'>Are you sure you want to delete the following RAPID ID?</div>
        </div>
        <div className='info-wrapper'>
          <p style={{ color: 'red', textAlign: 'center' }}>{rapidID}</p>
        </div>
        <div className='btn-wrapper'>
          <button className='btn' onClick={submit}>Delete</button>
          <button className='btn' onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
export default DeleteValidRapidID;
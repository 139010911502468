/**
 * @author Aung Khant Kyaw
 */

import React, { useState } from 'react';
import '../styles/PopUp.css';

const AddNewValidRapidID = ({ onClose, handleSubmit, rapidIdObjects, validRapidIdObjects}) => {
  const [inputText, setInputText] = useState('');
  const [isTextError, setIsTextError] = useState(false); // State for error message
  const [isDuplicatedError, setIsDuplicatedError] = useState(false); // State for error message

  // Checking to make sure only letter and number are accepted for rapid id
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputText(inputValue);
    const regex = /^[a-zA-Z0-9]+$/;
    setIsTextError(!regex.test(inputValue));
    setIsDuplicatedError(checkDuplicateRapidID(inputValue));
  };

  const submitValidRapidID = () => {
    if (!isTextError && !isDuplicatedError) { // Check for error and duplicate
      handleSubmit(inputText);
      onClose();
    }
  }

  const checkDuplicateRapidID = (newRapidID) => {
    return rapidIdObjects.some((existingRapidID) => existingRapidID.rapid_id === newRapidID) || validRapidIdObjects.some((existingValidRapidID) => existingValidRapidID.valid_rapid_id === newRapidID);
  };
  return (
    <div className="modal-container">
      <div className='modal-overlay'></div>
      <div className='modal-wrapper-validRapidID'>
        <div className='top-wrapper'>
          <div  className='title'>Enter New Valid Rapid ID</div>
        </div>
        <div className='info-wrapper'>
          <input className='info-wrapper-validRapidID-input' type="text" value={inputText} onChange={handleChange} />
          {isTextError && <p style={{ color: 'red' }}>Only letters and numbers are accepted for RAPID ID.</p>}
          {isDuplicatedError && <p style={{ color: 'red' }}>This RAPID ID already exist in Database.</p>}
        </div>
        <div className='btn-wrapper'>
          <button className='btn' onClick={submitValidRapidID}>Submit</button>
          <button className='btn' onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
export default AddNewValidRapidID;
import React, { useState, useEffect } from "react";
import "../styles/PatientView.css";
import triton_logo from "../images/triton_logo.jpg";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {Navigate, useNavigate, useLocation } from "react-router-dom";
import Slider from 'react-slider';
import axios from "axios";
import useAuth from '../utils/auth/useAuth.js';

import SurveyPopup from '../components/SurveyPopUp';
import ApptContainer from '../components/ApptContainer'

/**
 * Component to provide the rendering and logic for the patient or RAPID ID view 
 * @component
 * @returns {React.HTMLAttributes}
 */
function PatientView() {
    const { email, user_id, jwt, role, isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    console.log("location:", location);

    const [showPopup, setShowPopup] = useState(false);
    const rapid_id = location.state.rapid_id;
    console.log(rapid_id);

    const [drug, setDrug] = useState("Rotigotine");
    const [infusionDose, setInfusionDose] = useState(0);
    const [schedule, setSchedule] = useState([]);
    const [showAddAptPopup, setShowAddAptPopup] = useState(false);
    const [appointments, setAppointments] = useState([]);

    // Survery Pop Up 
    const [showSurveyPopup, setShowSurveyPopup] = useState(false);
    const [survey,  setSurvey] = useState([]);
    const surveyAdded = survey.length > 0;
    const [required, setRequired]  =  useState(false);

    /**
     * @function
     * This function is called whenever the state is updated to reflect current state variable data. We use it to load (and reload) the appointments associated with the user. 
     */
    useEffect(() => {
        console.log("in useeffect func");
        // if not isAuthenticated, redirect to signin
        if (!isAuthenticated){
            return <Navigate to={'/signin'} replace='true' />;
        }
        if(isLoading){
            const getAptsPerRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/appointments/" + rapid_id;
            axios.get(getAptsPerRapidIDEndpoint,{
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            })
            .then((response) => {
                const upcomingAppointments = response.data.filter(appointment => {
                    const appointmentDate = new Date(new Date(appointment.scheduled_start).toLocaleString()); // Parse scheduled_start as a Date object
                    return appointmentDate > new Date() && appointment.appointment_status !== "Completed"; // Check if appointment date is after the current date and time
                });
                // Sort upcomingAppointments by scheduled_start in ascending order
                upcomingAppointments.sort((a, b) => {
                    const dateA = new Date(a.scheduled_start);
                    const dateB = new Date(b.scheduled_start);
                    return dateA - dateB; // Sort by comparing timestamps (ascending order)
                });
                console.log("Appointment(s) per user after filtering past appointments:", upcomingAppointments)
                setAppointments(upcomingAppointments);
                console.log(appointments);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
        }
    });

    /**
     * This function naviagates the the correct appointment view when the user selects one of the appointment rows from the table
     * @param {React.HTMLAttributes} appointment 
     * @returns {null}
     */
    const handleAptRowClick = (appointment) => () => {
    // Implement your logic for handling appointment row click here
        console.log(`Appointment clicked: ${appointment.rapid_id_assigned_to} at ${appointment.scheduled_start}`);
        navigate("/aptview", {
            state: {
                email: email,
                user_id: user_id,
                selectedApt: appointment
            }
        })
    };

    /**
     * This function updates the state value when the dosage rate slider is changed
     * @param {Float} newValue 
     */
    const handleInfusionDoseSliderChange = (newValue) => {
        setInfusionDose(newValue);
    }
    
    /**
     * This function calls the appointment row click even handler when an appointment row is selected via keyboard navigation
     * @param {React.ChangeEvent} event 
     * @param {React.HTMLAttributes} appointment 
     */
    const handleAptRowKeyDown = (event, appointment) => {
        if (event.key === "Enter") {
        handleAptRowClick(appointment)();
        }
    };

    /**
     * Hook to handle when the button to add a new appointment is selected
     */
    const handleAddAptBtnClick = () => {
        // Implement your logic for adding a new Rapid ID here
        console.log("Add Apt button clicked");
        console.log("schedule:", schedule)
        setShowAddAptPopup(true);
    };

    /**
     * Hook to handle hover enter events on the table rows
     * @param {React.ChangeEvent} e 
     */
    const handleTableRowMouseEnter = (e) => {
        console.log("e:",e);
        e.target.parentElement.style.backgroundColor = "#f5f5f5";
    };

    /**
     * Hook to handle hover leave events on table rows
     * @param {React.ChangeEvent} e 
     */
    const handleTableRowMouseLeave = (e) => {
        e.target.parentElement.style.backgroundColor = "white";
    };

    /**
     * Hook to trigger the deletion popup when the delete appointment is selected
     */
    const handleDelClick = () => {
        setShowPopup(true);
    }

    /**
     * Hook to return to main menu screen when the user is done editing the patient appointments
     * @param {React.ChangeEvent} event 
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("Rapid ID:", rapid_id);
        console.log("Appointments:", appointments);

        navigate("/main", {
        state: {
            email: email,
            user_id: user_id,
        },
        });
    };

    /**
     * Hook to close the popup when the close button is selected
     */
    const handlePopupCancel = () => {
        setShowPopup(false);
    };

    /**
     * Hook to cancel adding new appointments when the popup is cancelled
     */
    const handleAddAptPopupCancel = () => {
        setShowAddAptPopup(false);
    }

    /**
     * Hook to call API to a new appointment(s) for the current RAPID ID when the user accepts the submit popup 
     */
    const handleAddAptPopupClick = () => {
        console.log("Rapid ID:", rapid_id);
        console.log("Entered Drug:", drug);
        console.log("Entered Infusion Dose :", infusionDose);
        console.log("Entered Scheduled Date:", schedule[0].toString());
        console.log("Email:", email);
        console.log("RequiredSurvey", survey);
        console.log("Survey", survey);
        setShowAddAptPopup(false);

        const addAptEndpoint = String(process.env.REACT_APP_API_URI) + "/appointment";
        for (var date in schedule){
            const selectedDate = new Date(schedule[date].toString());
            const utcDate = selectedDate.toISOString();;
            axios.post(addAptEndpoint, {
                rapid_id_assigned_to: rapid_id,
                user_assigned_to: user_id,
                drug: drug,
                infusion_dose: infusionDose,
                step_count:0,
                step_count_log:[],
                time_increment:0,
                scheduled_start: utcDate,
                infusion_start_time: "N/A",
                amt_administered: 0,
                appointment_status: "Not Started",
                pump_status:false,
                required_survey: required,
                surveys: survey
            },{
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            }).then((response) => {
                console.log(response);
                setDrug("Rotigotine");
                setInfusionDose("0");
                setSchedule([]);
                setIsLoading(true);
            }, (error) => {
                console.log(error);
                setDrug("Rotigotine");
                setInfusionDose("0");
                setSchedule([]);
            });
        }
        setDrug("Rotigotine");
        setInfusionDose("0");
        setSchedule([]);
        setIsLoading(true);
    }

    /**
     * Hook to handle changes to the drug dropdown
     * @param {React.ChangeEvent} event 
     */
    const handleDropdownChange = (event) => {
        event.preventDefault();
        setDrug(event.target.value);
    }

    /**
     * Hook to handle deletion of a RAPID ID when the user confirms deletion in the deletion popup. Also deletes all appointments associated with the user. 
     */
    const handlePopupDelete = () => {
        console.log("deleting rapid_id:", rapid_id);
        const deleteRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/rapid_id/" + rapid_id;
        const deleteAptEndpointBase = String(process.env.REACT_APP_API_URI) + "/appointment/";

        console.log(appointments);

        for(var appointment in appointments) {
            console.log(appointments[appointment]);
            var deleteAptEndpoint = deleteAptEndpointBase + appointments[appointment]._id.$oid;
            console.log("delete apt endpoint: ", deleteAptEndpoint);
            axios.delete(deleteAptEndpoint, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            }).then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
            });
        }
        axios.delete(deleteRapidIDEndpoint, {
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            navigate("/main", {
                state:{
                    email: email,
                    user_id: user_id
                }
            });
        }, (error) => {
            console.log(error);
        });
        setShowPopup(false);
    };

    const handleAddSurvey = () => {
        setShowSurveyPopup(!showSurveyPopup);
    };

    const handleSurveyQuestion = (questions,  isRequired) => {
        setSurvey(questions);
        setRequired(isRequired);
        // setShowSurveyPopup(false);
    }

    /**
     * Rendering logic for the patient view
     */
    if (isLoading || appointments === null) {
        console.log("loading...");
        return <p>Loading...</p>;
    }
    else {
        console.log("done loading");
        return (
            <div className="patient-view-page">
            <header className="header3">
                <button onClick={() => 
                    navigate(-1)
                }>
                    Back
                </button>
                <div className="log2">
                <img src={triton_logo} alt="Triton Logo" />
                </div>
            </header>
            <br/>
            <div className="titleSpace">
                <h2>RAPID ID Info</h2>
                <button onClick={() => 
                    navigate("/addnewid", {
                        state:{
                            email: email,
                        }
                    })
                }>
                    Add New RAPID ID
                </button>
            </div>
            <form className="patient-view-form" onSubmit={handleSubmit}>
                <div className="rapid-section">
                    <label htmlFor="rapid_id">RAPID ID: {rapid_id}</label>
                    <button type="button" onClick={handleDelClick}>
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.12 10.47L12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"/></svg>                    
                    </button>
                </div>
                <br/>
                <ApptContainer rapid_id={rapid_id} handleSubmit={handleSubmit} 
                    drug={drug} handleDropdownChange={handleDropdownChange} 
                    infusionDose={infusionDose} setInfusionDose={setInfusionDose} 
                    handleInfusionDoseSliderChange={handleInfusionDoseSliderChange}
                    showAddAptPopup={showAddAptPopup} showPopup={showPopup} schedule={schedule} setSchedule={setSchedule}
                    handleAddSurvey={handleAddSurvey} surveyAdded={surveyAdded} handleAddAptBtnClick={handleAddAptBtnClick}
                    appointments={appointments} handleAptRowClick={handleAptRowClick} handleAptRowKeyDown={handleAptRowKeyDown}
                    handleTableRowMouseEnter={handleTableRowMouseEnter} handleTableRowMouseLeave={handleTableRowMouseLeave}
                />
                <br/>
                <button type="submit" disabled={!rapid_id}>Done</button>
            </form>
            {showPopup && (
                <div className="popup">
                    <div className="content">
                        <h3>Are you sure?</h3>
                        <p>
                            Please confirm you would like to delete RAPID-ID User: {rapid_id}.
                        </p>
                        <button type="button" onClick={handlePopupDelete}>Delete</button>
                        <button type="button" onClick={handlePopupCancel}>Cancel</button>
                    </div>
                </div>
            )}

            {showAddAptPopup && (
                <div className="popup">
                <div className="content">
                    <h3>Add appointment?</h3>
                    <p>
                        Please confirm you would like to add a new appointment for RAPID User: {rapid_id} 
                        with the following details:
                    </p>
                    <p>
                        Drug: {drug}
                    </p>
                    <p>
                        Infusion Dose: {infusionDose} mg
                    </p>
                    <p>
                        Appointment Schedule: {
                                schedule.map(apt => (
                                    <p style={{fontSize: "25px"}}>{apt.toString()}</p>
                                )
                            )} 
                    </p>
                    <button type="button" onClick={handleAddAptPopupClick}>Add</button>
                    <button type="button" onClick={handleAddAptPopupCancel}>Cancel</button>
                </div>
            </div>
            )}

            {showSurveyPopup && <SurveyPopup onClose={() => setShowSurveyPopup(false)} handleSurveySubmit={(surveyQuestion, required) => handleSurveyQuestion(surveyQuestion, required)} />}
            </div>
        );
    }
}

export default PatientView;
import React, { useState } from "react";
import '../styles/Signin.css';
import triton_logo from '../images/triton_logo.jpg';
import useAuth from '../utils/auth/useAuth.js';
import { redirect, useNavigate } from "react-router-dom";
import axios from "axios";
import bcryptjs from 'bcryptjs';

/**
 * This component provides the rendering and logic for the signin process
 * @component
 * @returns {React.HTMLAttributes}
 */
function Signin() {
  const { loadUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  /**
   * Hook to handle selection of the sign in button
   * @param {*} event Event returned when the sign in button is clicked
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Implement your sign in logic here
    const getSaltEndpoint = String(process.env.REACT_APP_API_URI) + "/login/" + email; //Endpoint to retrieve salt from API
    axios.get(getSaltEndpoint).then((response) => {//get the salt for the user
      let salt = response.data.salt;
      let hashedPassword = bcryptjs.hashSync(password, salt);//hash the entered password with the user's salt

      const signinEndpoint = String(process.env.REACT_APP_API_URI) + "/login";//endpoint to log the user in
      axios.post(signinEndpoint, {//'submit the sign in credentials with the hashed password
        email: email,
        password: hashedPassword
      }).then(async (response) => {
        // store token to localstorage
        localStorage.setItem('PROMPT-token',
          JSON.stringify({
            user_id: response.data.id.$oid,
            email: response.data.email,
            jwt: response.data.jwt_token,
            role: response.data.role
          }));
        localStorage.setItem('PROMPT-isAuthenticated', true);  
        await loadUser();
        if (response.data.role === "admin"){
          console.log(response.data.role)
          navigate("/admin", { replace: true })
        } else {
          navigate("/admin", { replace: true })
        }
      }, (error) => {
        console.log(error);
        setShowErrorPopup(true);
      });
    }, (error) => {
      console.log(error);
      setShowErrorPopup(true);
    })
  };

  /**
   * Hook to handle popup close
   */
  const handleClosePopup = () => {
    setShowErrorPopup(false);
    
};

  /**
   * Return the rendering HTML for the page
   */
  return (
    <div className="sign-in-page">
      <header className="header">
        <a href="/">
          <button className="home-button">
            Back
          </button>
        </a>
        <span class="tab"></span>
        <div className="logo2">
          <img src={triton_logo} alt="Triton Logo" />
        </div>
      </header>
      <br/><br/>
      <div className="signInContainer">
        <div className="titleHeading">Sign In</div>
        <form className="sign-in-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Email Address:</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            placeholder="Enter your email address" 
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <label htmlFor="password">Password:</label>
          <input 
            type="password" 
            id="password" 
            name="password"
            placeholder="Enter your password" 
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <br/>
          <button type="submit">Sign In</button>
        </form>
        <div className="forgot-password">
          <a href="/forgotpassword">Forgot your password?</a>
        </div>
        <div className="new-user">
          <p>
            New user? <a href="/signup">Sign Up</a>
          </p>
        </div>
        </div>
        {showErrorPopup && (
        <div className="popup">
            <div className="content">
                <h3>Invalid Login Credentials</h3>
                <p>
                    Sorry, no account was found with for the provided credentials. Please try again.
                </p>
                <button onClick={handleClosePopup}>Close</button>
            </div>
        </div>
      )}
    </div>
  );
}

export default Signin;
import React, { useContext, useState, useEffect } from "react";
import '../styles/Main.css';
import triton_logo from '../images/triton_logo.jpg'
import { redirect, Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import useAuth from '../utils/auth/useAuth.js';

/**
 * This component provides the rendering and logic for the "main menu" view for the user
 * @component
 * @returns {React.HTMLAttributes}
 */
function Main() { 

    const { email, user_id, jwt, role, isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();
    const [rapidIds, setRapidIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rapidIdsLoading, setRapidIdsLoading] = useState(true);
    const [appointmentsLoading, setAppointmentsLoading] = useState(true);
    const [upcomingAppointments, setUpcomingAppointments] = useState([]);
    const [completedAppointments, setCompletedAppointments] = useState([]);

    
    /**
     * This function refreshes the page whenever it is first loaded or one of the state variables is updated. Here it is used to 
     * get the RAPID IDs and Appointments associated with the user. 
     */
    useEffect(() => {
        // if not isAuthenticated, redirect to signin
        if (!isAuthenticated){
            return <Navigate to={'/signin'} replace='true' />;
        }
        if(isLoading){
            const getRapidIdsPerUserEndpoint = String(process.env.REACT_APP_API_URI) + "/rapid_ids/" + user_id;
            axios.get(getRapidIdsPerUserEndpoint, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            })
            .then((response) => {
                console.log("RapidID per user: ", response);
                setRapidIds(response.data);
                setRapidIdsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setRapidIdsLoading(false);
            });
            
            const getAptsPerUserEndpoint = String(process.env.REACT_APP_API_URI) + "/user-appointments/" + user_id;
            axios.get(getAptsPerUserEndpoint, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    },
            })
            .then(response => {
                // Filter appointments based on scheduled_start in the future
                const upcomingAppointments = response.data.filter(appointment => {
                    const appointmentDate = new Date(new Date(appointment.scheduled_start).toLocaleString()); // Parse scheduled_start as a Date object
                    return appointmentDate > new Date() && appointment.appointment_status !== "Completed"; // Check if appointment date is after the current date and time
                });
                // Sort upcomingAppointments by scheduled_start in ascending order
                upcomingAppointments.sort((a, b) => {
                    const dateA = new Date(a.scheduled_start);
                    const dateB = new Date(b.scheduled_start);
                    return dateA - dateB; // Sort by comparing timestamps (ascending order)
                });
                console.log("Appointment(s) per user after filtering past appointments:", upcomingAppointments)
                setUpcomingAppointments(upcomingAppointments);
                // Filter appointments based appointment_status === "Completed"
                const completedAppointments = response.data.filter(appointment => {
                    // const appointmentDate = new Date(new Date(appointment.scheduled_start).toLocaleString()); // Parse scheduled_start as a Date object
                    return appointment.appointment_status === "Completed";
                });
                // Sort upcomingAppointments by scheduled_start in ascending order
                completedAppointments.sort((a, b) => {
                    const dateA = new Date(a.scheduled_start);
                    const dateB = new Date(b.scheduled_start);
                    return dateA - dateB; // Sort by comparing timestamps (ascending order)
                });
                console.log("Appointment(s) per user after filter completed appointments", completedAppointments)
                setCompletedAppointments(completedAppointments);
                setAppointmentsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setAppointmentsLoading(false);
            });
            if(!rapidIdsLoading && !appointmentsLoading){
                setIsLoading(false);
            }
    }});
    
    /**
     * Hook to handle when one of the RAPID ID table rows is selected, loads the correct patient view. 
     * @param {React.HTMLAttributes} id 
     * @returns {null}
     */
    const handleIDRowClick = (id) => () => {
        // Implement your logic for handling ID row click here\
        navigate("/patientview", {
            state: {
                email: email,
                rapid_id: id,
                user_id: user_id
            }
        })
    };

    /**
     * Hook to handle when one of the appointment table rows is selected, loads the correct appointment view. 
     * @param {React.HTMLAttributes} appointment 
     * @returns {null}
     */
    const handleAptRowClick = (appointment) => () => {
    // Implement your logic for handling appointment row click here
        console.log(`Appointment clicked: ${appointment.rapid_id_assigned_to} at ${appointment.scheduled_start}`);
        navigate("/aptview", {
            state: {
                email: email,
                selectedApt: appointment,
                user_id: user_id
            }
        })
    };

    /**
     * Hook to handle keyboard selection of table row
     * @param {React.ChangeEvent} event 
     * @param {React.HTMLAttributes} id 
     */
    const handleAptRowKeyDown = (event, appointment) => {
        if (event.key === "Enter") {
            handleAptRowClick(appointment)();
        }
    };
    
    /**
     * Hook to handle when one of the completed appointment table rows is selected, loads the correct appointment view. 
     * @param {React.HTMLAttributes} appointment 
     * @returns {null}
     */
    const handleCompletedAptRowClick = (appointment) => () => {
        // Implement your logic for handling appointment row click here
            console.log(`Appointment clicked: ${appointment.rapid_id_assigned_to} at ${appointment.scheduled_start}`);
            navigate("/aptsum", {
                state: {
                    email: email,
                    selectedApt: appointment,
                    user_id: user_id
                }
            })
        };

    /**
     * Hook to handle keyboard selection of table row
     * @param {React.ChangeEvent} event 
     * @param {React.HTMLAttributes} id 
     */
    const handleCompletedAptRowKeyDown = (event, appointment) => {
        if (event.key === "Enter") {
            handleCompletedAptRowClick(appointment)();
        }
    };

    /**
     * Hook to handle when the user selcts the logout button. Resets the state and returns them to the landing page. 
     */
    const handleLogoutClick = () => {
        const logoutEndpoint = String(process.env.REACT_APP_API_URI) + "/logout/" + String(user_id);
        axios.delete(logoutEndpoint, {
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then(async (response) => {
            await logout();
            navigate("/")
        }, (error) => {
            console.log(error);
        } 
        )
    }

    /**
     * Hook to handle keyboard selection of table row
     * @param {React.ChangeEvent} event 
     * @param {React.HTMLAttributes} id 
     */
    const handleIDRowKeyDown = (event, id) => {
        if (event.key === "Enter") {
            handleIDRowClick(id)();
        }
    };

    /**
     * Hook to handle when the add RAPID ID button is selected. Sends the user to the add RAPID ID page. 
     */
    const handleAddRapidBtnClick = () => {
        // Implement your logic for adding a new rapid ID here
        navigate("/addnewid", {
            state: {
                email: email,
                user_id: user_id
            }
        })
    };

    /**
     * Hook to handle enter hover event for table rows
     * @param {React.MouseEvent} e 
     */
    const handleTableRowMouseEnter = (e) => {
        console.log("e:",e);
        e.target.parentElement.style.backgroundColor = "#f5f5f5";
    };

    /**
     * Hook to handle exit hover event for table rows
     * @param {React.MouseEvent} e 
     */
    const handleTableRowMouseLeave = (e) => {
        e.target.parentElement.style.backgroundColor = "white";
    };

    /**
     * Rendering logic for component
     */
    if (isLoading || rapidIds === null || upcomingAppointments === null) {
        console.log("loading...");
        return <p>Loading...</p>;
    }
    else{
        console.log("done loading");
        return (
            <div className="dashboard-container">
                <div className="header-container">
                <button className="add-rapid-btn" onClick={handleLogoutClick}>
                    Log Out
                </button>
                <span className="welcome-message">Welcome, {email}!</span>
                <button
                    className="add-rapid-btn"
                    onClick={handleAddRapidBtnClick}
                >
                    Add a new RAPID ID
                </button>
                </div>
                <div className="content-container">
                <div className="tables-container">
                    <div className="rapid-ids-container">
                    <h2>Assigned IDs</h2>
                    {rapidIds.length === 0 ? (
                        <p>No RAPID IDs found</p>
                    ) : (
                        <table className="rapid-ids-table">
                        <thead>
                            <tr>
                                <th>RAPID ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rapidIds.map((rapid_id) => (
                            <tr
                                key={rapid_id.rapid_id}
                                tabIndex="0"
                                role="button"
                                onClick={handleIDRowClick(rapid_id.rapid_id)}
                                onKeyDown={(event) => handleIDRowKeyDown(event, rapid_id.rapid_id)}
                                onMouseEnter={handleTableRowMouseEnter}
                                onMouseLeave={handleTableRowMouseLeave}
                            >
                                <td>{rapid_id.rapid_id}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    )}
                    </div>
                    <div className="appointments-container">
                        <h2>Upcoming Appointments</h2>
                        {upcomingAppointments.length === 0 ? (
                            <p>No Upcoming Appointments Found</p>
                        ) : (
                            <table className="appointments-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>RAPID ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {upcomingAppointments.map((appointment) => (
                                <tr
                                    key={appointment._id.$oid}
                                    tabIndex="0"
                                    role="button"
                                    onClick={handleAptRowClick(appointment)}
                                    onKeyDown={(event) => handleAptRowKeyDown(event, appointment)}
                                    onMouseEnter={handleTableRowMouseEnter}
                                    onMouseLeave={handleTableRowMouseLeave}
                                >
                                    <td>{new Date(appointment.scheduled_start).toLocaleDateString()}</td>
                                    <td>{new Date(appointment.scheduled_start).toLocaleTimeString()}</td>
                                    <td>{appointment.rapid_id_assigned_to}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        )}
                    </div>
                    <div className="appointments-container">
                        <h2>Completed Appointments</h2>
                        {completedAppointments.length === 0 ? (
                            <p>No Upcoming Appointments Found</p>
                        ) : (
                            <table className="appointments-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>RAPID ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {completedAppointments.map((appointment) => (
                                <tr
                                    key={appointment._id.$oid}
                                    tabIndex="0"
                                    role="button"
                                    onClick={handleCompletedAptRowClick(appointment)}
                                    onKeyDown={(event) => handleCompletedAptRowKeyDown(event, appointment)}
                                    onMouseEnter={handleTableRowMouseEnter}
                                    onMouseLeave={handleTableRowMouseLeave}
                                >
                                    <td>{new Date(appointment.scheduled_start).toLocaleDateString()}</td>
                                    <td>{new Date(appointment.scheduled_start).toLocaleTimeString()}</td>
                                    <td>{appointment.rapid_id_assigned_to}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        )}
                    </div>
                </div>
                </div>
                <div className="footer-container">
                    <img src={triton_logo} alt="Triton Logo" />
                </div>
            </div>
        );
    }
}

export default Main;
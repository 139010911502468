import React, { useState, useEffect } from 'react';
import triton_logo from '../images/triton_logo.jpg';
import { useNavigate, useLocation  } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import { ResizableBox } from 'react-resizable';
import Countdown from 'react-countdown';
import {
    LineChart,
    ResponsiveContainer,
    Legend,
    Tooltip,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
} from "recharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import '../styles/AptView.css';
import useAuth from '../utils/auth/useAuth.js';

/**
 * This component provides the rendering and logic for the appointment view of the application.
 * @component
 * @returns {React.HTMLAttributes}
 */
const AptView = () => {
    const { email, user_id, jwt, role, isAuthenticated, logout } = useAuth();
    const location = useLocation();
    const selectedApt = location.state.selectedApt;
    console.log("selectedApt:", selectedApt);
    const navigate = useNavigate();

    // Add state variables for each input
    const [isLoading, setIsLoading] = useState(true);
    const [currentApptLoading, setCurrentApptLoading] = useState(true);
    const [currentAppt, setCurrentAppt] = useState();
    const [pumpStatus, setPumpStatus] = useState(selectedApt.pump_status? "Connected": "Disconnected"); // Initial pump status is off
    const [aptStatus, setAptStatus] = useState(selectedApt.appointment_status);
    const [showDelPopup, setShowDelPopup] = useState(false);
    const [lastStepCount, setLastStepCount] = useState();
    const [aptWithinOneHour, setAptWithinOneHour] = useState();
    const [vitalSignsData, setVitalSignsData] = useState();
    const [disabledBtn, setDisableBtn] = useState(true);

    // Websocket variable
    const [socket, setSocket] = useState(null);
    // Websocket Endpoint
    const websocketEndpoint = 'wss://api.rapidprompt.net/ws/'
    // const websocketEndpoint = 'ws://localhost:8000/ws/'

    // Emergency feature is not needed as of right now: Sep 17, 2024
    // const [showEmergencyPopup, setShowEmergencyPopup] = useState(false);
    // The Phycisian only notify the patient. The patient will start the infusion so no need for popup or countdown
    // const [showStartPopup, setShowStartPopup] = useState(false);
    // const [showStopPopup, setShowStopPopup] = useState(false);
    // const [startCountdownActive, setStartCountdownActive] = useState(false);

    const [monitorStatus, setMonitorStatus] = useState("Disconnected"); // Initial monitor status is off

    // const exampleVitalSigns = [
    //     {
    //         timestamp: "2024-01-03 12:34:12",
    //         heartRate: 75,
    //         bloodSugar: 70,
    //         bloodPressureSystolic: 110,
    //         bloodPressureDiastolic: 75,
    //     },
    //     {
    //         timestamp: "2024-01-03 12:34:17",
    //         heartRate: 70,
    //         bloodSugar: 75,
    //         bloodPressureSystolic: 100,
    //         bloodPressureDiastolic: 60,
    //     },
    //     {
    //         timestamp: "2024-01-03 12:34:22",
    //         heartRate: 79,
    //         bloodSugar: 80,
    //         bloodPressureSystolic: 120,
    //         bloodPressureDiastolic: 80,
    //     },
    //     {
    //         timestamp: "2024-01-03 12:34:27",
    //         heartRate: 60,
    //         bloodSugar: 60,
    //         bloodPressureSystolic: 100,
    //         bloodPressureDiastolic: 65,
    //     },
    //     {
    //         timestamp: "2024-01-03 12:34:32",
    //         heartRate: 63,
    //         bloodSugar: 64,
    //         bloodPressureSystolic: 112,
    //         bloodPressureDiastolic: 73,
    //     },
    // ];

    /**
     * UseEffect function runs on page load and every time a state variable is updated, Used here to fetch updated to the vital signs. 
     */
    useEffect(() => {
        console.log("Apt View: useEffect: Getting Current Appointment Data");
        // Initial fetch
        fetchData().then(() => {
            setIsLoading(false);
            // While in page AND withing one hour of appointment start time, join Server websocket
            if (isWithinOneHour(new Date(selectedApt.scheduled_start).toLocaleString())){
                // Call connect to initiate the connection
                connect();
                // const websocketID = selectedApt._id.$oid + "_" + user_id; // Unique ID for appt ID + user ID
                // const websocket = new WebSocket(websocketEndpoint + websocketID);
                // // Log connection status
                // websocket.onopen = function() {
                //     console.log(`${websocketID} connected`);
                // };
                // websocket.onclose = function(event) {
                //     console.log(`${websocketID} closed. Code: ${event.code} Reason: ${event.reason}`);
                //     // Reconnect logic only if the close was unexpected
                //     if (event.code !== 1000 || event.reason !== "Appointment Completed") {
                //         console.log("Reconnecting...");
                //         setTimeout(connect, 1000); // Reconnect after 1 second delay
                //     }
                // };
                // // When receive a message from other websocket, fetchData again to update.
                // websocket.onmessage = function(event) {
                //     const msg = event.data;
                //     const realMsg = msg.substring(0,msg.indexOf("from ")-1);
                //     const substringMsg = msg.substring(msg.indexOf("from ") + 5);
                //     const websocketClient = selectedApt._id.$oid + "_" + selectedApt.rapid_id_assigned_to;
                    
                //     console.log(msg + ": " + realMsg + ": " + substringMsg + ": " + websocketClient);
                //     // Make sure the message is from appointment ID + "_" +selectedApt.rapid_id_assigned_to
                //     if (substringMsg === websocketClient){
                //         fetchData()
                //     }
                //     // close the websocket if the message is Appointment Completed
                //     if (realMsg === "Appointment Completed") {
                //         websocket.close(1000, "Appointment Completed"); // Close gracefully
                //     }
                // };
            }
        });

        
        // if (aptStatus === "In Progress"){
        //     const intervalId = setInterval(fetchData, 3000); // Fetch every 5 seconds
        //     return () => clearInterval(intervalId); // Clear interval on unmount
        // }

        // Fetch data from your API and update state
        // fetch('/api/vital-signs')
        // .then((response) => response.json())
        // .then((data) => setVitalSignsData(exampleVitalSigns));
    }, [ ]);

    // ********************** Appointment Deletion Handlers ********************* //
    /**
     * Hook to handle selection of the delete appointment button. Brings up the delete confirmation popup.
     */
    const handleAptCancel = () => {
        setShowDelPopup(true);
    }

    /**
     * Hook to handle selection of the cancel button in the delete popup. 
     */
    const handlePopupCancel = () => {
        setShowDelPopup(false);
    };

    /**
     * Hook to handle selection of the confirm deletion button in the delete popup. Calls the API to delete the appointment from the database. 
     */
    const handlePopupDelete = () => {
        setShowDelPopup(false);
        console.log("deleting appointment:", selectedApt);
        const deleteAptEndpoint = String(process.env.REACT_APP_API_URI) + "/appointment/" + selectedApt._id.$oid;
        axios.delete(deleteAptEndpoint, {
            headers: {
                    Authorization: `Bearer ${jwt}`
                },
        }).then((response) => {
            console.log(response);
            if (socket!=null){
                socket.close(1000, "Appointment Done, Websocket Closed");
            }
            navigate(-1, {
                state:{
                    email: email,
                    user_id: user_id
                }
            })
        })
    };

    // ********************** Notifying Appointment Handlers and Methods ********************* //
    /**
    * Hook to handle when the phycisian starts the infusion and infusion start on the patient side
    */
    const handleStartApt = async () => {
        console.log("StartApt button clicked");
        // Change Appointment status using api, then notified the patient
        const targetId = selectedApt._id.$oid + "_" + selectedApt.rapid_id_assigned_to;
        const message = "Start Appointment"
        const msg = `${targetId}: ${message}`;
        socket.send(msg);
        // set disabled btn to true
        setDisableBtn(false);
        // const updateApptStatusEndPoint = String(process.env.REACT_APP_API_URI) + "/update_appointment_status/" + selectedApt._id.$oid;
        // try {
        //     axios.put(updateApptStatusEndPoint, {
        //         appointment_field: "Notified",
        //     },{
        //         headers: {
        //                 Authorization: `Bearer ${jwt}`
        //             },
        //     }).then((response) => {
        //         console.log(response);
        //     }, (error) => {
        //         console.log(error);
        //     });
        // } catch (error) {
        //     console.log(error);
        // }

    }

    // ********************** Other Helper Methods ********************* //

    // Websocket connection
    const connect = () => {
        const websocketID = selectedApt._id.$oid + "_" + user_id; // Unique ID for appt ID + user ID
        const websocket = new WebSocket(websocketEndpoint + websocketID);
        setSocket(websocket);

        websocket.onopen = function() {
        console.log(`${websocketID} connected`);
        };
    
        websocket.onclose = function(event) {
            console.log(`${websocketID} closed. Code: ${event.code} Reason: ${event.reason}`);
        
            // Reconnect logic only if the close was unexpected
            if (event.reason !== "Appointment Done, Websocket Closed") {
                console.log("Reconnecting...");
                setTimeout(connect, 1000); // Reconnect after 1 second delay
            }
        };
    
        websocket.onmessage = function(event) {
            const msg = event.data;
            const realMsg = msg.substring(0, msg.indexOf("from ") - 1);
            const substringMsg = msg.substring(msg.indexOf("from ") + 5);
            const websocketClient = selectedApt._id.$oid + "_" + selectedApt.rapid_id_assigned_to;
        
            console.log(msg + ": " + realMsg + ": " + substringMsg + ": " + websocketClient);
        
            if (substringMsg === websocketClient) {
                fetchData();
            }
        };
    };

    // Method to get fetch appointment details based on the selected apt ID
    const fetchData = async () => {
        const addAptEndpoint = String(process.env.REACT_APP_API_URI) + "/appointment/" + selectedApt._id.$oid;
        try {
            const response = await axios.get(addAptEndpoint, {
                headers: {
                Authorization: `Bearer ${jwt}`
                }
            });
            console.log(response.data);
            setCurrentAppt(response.data);
            setAptStatus(response.data.appointment_status);
            setPumpStatus(response.data.pump_status ? "Connected" : "Disconnected");
            setCurrentApptLoading(false);
            // set the appointment step count log to show the last known stepcount
            if (response.data.step_count_log){
                setLastStepCount(response.data.step_count_log[response.data.step_count_log.length - 1]);
            }
            // set the appointment vsm log to update the graph
            if (response.data.vsm_log){
                const convertedData = response.data.vsm_log.map((record) => {
                    var timeStamp = new Date(record.time).toLocaleString(); // Convert to user's local time
                    return {
                      ...record, // Spread existing record properties
                      time: timeStamp // Replace "time" with desired format
                    };
                });
                setVitalSignsData(convertedData);
            }
            // check if the start time is within one hour
            var apptStartTime = new Date(response.data.scheduled_start).toLocaleString();
            setAptWithinOneHour(isWithinOneHour(apptStartTime));
            // If aptStatus === "Completed", take user to apt-sum page.
            console.log(selectedApt.pump_status);
            if (response.data.appointment_status === "Completed"){
                if (socket!=null){
                    socket.close(1000, "Appointment Done, Websocket Closed");
                }
                navigate("/aptsum", {
                    state: {
                        email: email,
                        selectedApt: response.data,
                        user_id: user_id
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    };

    function isWithinOneHour(scheduledStart) {
        const now = new Date();
        const scheduledStartTime = new Date(scheduledStart);
        const oneHourFromNow = new Date();
        oneHourFromNow.setHours(now.getHours() + 1);
        return scheduledStartTime >= now && scheduledStartTime <= oneHourFromNow;
    }
    
    // Emergency feature is not needed as of right now: Sep 17, 2024
    /**
     * Hook to handle selection of the emergency button. Brings up the emergency confirmation popup. 
     */
    // const handleEmergencyClick = () => {
    //     setShowEmergencyPopup(true);
    // };

    /**
     * Hook to handle selection of the cancel button in the emergency popup
     */
    // const handleEmergencyPopupCancel = () => {
    //     setShowEmergencyPopup(false);
    // };

    /**
     * Hook to handle confirmation of an emergency in the emergency popup
     */
    // const handleEmergencyPopupConfirm = () => {
    //     setShowEmergencyPopup(false);
    //     console.log("Emergency workflow triggered");
    //     setAptStatus("Stopped");
        
    // };

    /**
     * Hook to handle confirmation of appointment start in the start appointment popup. Triggers a 10 second countdown before changing application into startup state. 
     */
    // const handleStartInjection = () => {
    //     console.log("injection process is starting in 10 seconds");
    //     setStartCountdownActive(true);
    //     setShowStartPopup(false);
    // }

    /**
     * Hook to handle when the cancel button is selected in the infusion start confirmation popup.
     */
    // const handleCancelInjectionStart = () => {
    //     console.log("cancelling injection start");
    //     setShowStartPopup(false);
    // }

    /**
     * Hook to handle when the confirm stop button is selected in the stop confirmation popup
     */
    // const handleStopInjection = () => {
    //     console.log("stopping injection");
    //     setAptStatus("Stopped");
    //     setShowStopPopup(false);
    // }

    /**
     * Hook to handle when the  cancel button is selected on the start injection popup during the countdown
     */
    // const handleCountdownCancel = () => {
    //     console.log("Cancelling injection start while in countdown");
    //     //document.getElementById("countDown").stop;
    //     setStartCountdownActive(false);
    // }

    /**
     * Hook to handle when the close popup button is selected on the confirm stop appointment popup
     */
    // const handleCancelStopInjection = () => {
    //     console.log("closing stop appointment popup");
    //     setShowStopPopup(false);
    // }

    /**
     * Hook to handle when the countdown in the start appointment popup finishes and the injection starts
     */
    // const injectionStart = () => {
    //     console.log("injection started");
    //     setAptStatus("In Progress");
    //     setStartCountdownActive(false);
    // }

    /**
     * Rendering logic
     */
    if (isLoading || currentApptLoading) {
        console.log("loading...");
        return <p>Loading...</p>;
    } else {
        return (
            <div className="menu-screen">
                <div className="header4">
                    <div className="header-content">
                        <button className="back-home-button" 
                            disabled={aptStatus==="In Progress"}
                            onClick={() => {
                                    if (socket!=null){
                                        socket.close(1000, "Appointment Done, Websocket Closed");
                                    }
                                    navigate(-1)
                                }  
                            }>
                            Back
                        </button>
                        <div className="ScreenTitle">RAPID Infusion Control</div>
                        <div className="logo-container">
                            <img src={triton_logo} alt="Triton Logo" class="logo"/>
                        </div>
                    </div>
                </div>
                <main className="main">
                    <div className="left-column">
                        <div className='leftColumnHeader'>
                            <h2>System Control</h2>
                            {(aptStatus === "Not Started" || aptStatus === "Notified" ) && aptWithinOneHour && disabledBtn && (
                                <button onClick={handleStartApt} alt="Start/Stop Appointment" disabled={aptStatus==="Not Started"} >
                                    {aptStatus === "Not Started" ? (
                                            <p>Patient Not Ready</p>
                                        ) : (
                                            <p>Patient is Ready. Start infusion!</p>
                                        )
                                    }
                                </button>
                            )}
                            <div className='delAptButton'>
                                <button  onClick={handleAptCancel} disabled={aptStatus!=="Not Started"} alt="Delete Appointment">
                                    <svg xmlns="http://www.w3.org/2000/svg" alt="Delete Appointment" viewBox="0 0 24 24"  fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.12 10.47L12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"/></svg>
                                </button>
                            </div>
                        </div>
                        <div className='InfoPanel'>
                            <table className="aptInfoTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label htmlFor="rapid-id">RAPID ID:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.rapid_id_assigned_to}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="aptStatus">Appointment Status:</label>
                                        </td>
                                        <td>
                                            <label>{aptStatus}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="scheduled-start-time">{(currentAppt.infusion_start_time !== "N/A")? "Infusion Start Time:": "Scheduled Start Time:"}</label>
                                        </td>
                                        <td>
                                            <label>{(currentAppt.infusion_start_time !== "N/A")? new Date(currentAppt.infusion_start_time).toLocaleString(): new Date(currentAppt.scheduled_start).toLocaleString()}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="drug">Drug:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.drug}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="pump-status">Pump Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[pumpStatus]   
                                            }
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="monitor-status">Monitor Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Warning':
                                                        <div className="warningStatusIndicator">
                                                            Warning
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[monitorStatus]   
                                            }
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <label htmlFor="infusionDose">Dose rate: </label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.infusion_dose}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="stepCount">Step Count:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.step_count} </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="timeIncrement">Time Increment:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.time_increment} </label>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <label htmlFor="infusion-start-time">Infusion Start Time: </label>
                                        </td>
                                        <td>
                                            <label>{(currentAppt.infusion_start_time !== "N/A")? new Date(currentAppt.infusion_start_time).toLocaleString(): "N/A"} </label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="Current-Step-Count">Current Step Count:</label>
                                        </td>
                                        <td>
                                            {lastStepCount && (
                                                <label>{new Date(lastStepCount.time).toLocaleString()} - {lastStepCount.step_count}</label>
                                            )}
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <label htmlFor="amt-administered">Drug Amount Administered:</label>
                                        </td>
                                        <td>
                                            <div className="amtAdministeredDiv">
                                                <label>{currentAppt.amt_administered} mg</label>
                                                <span class="tab" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
    
                        <div className="alert-box">
                            <h3>Alert Status</h3>
                            <textarea id="alert-status" readOnly rows="6" style={{width: '100%'}} />
                        </div>
                    </div>
                    <div className="right-column">
                        <h2>RAPID Feedback</h2>
                        <div className="tabs">
                            <Tabs className="Tabs">
                                <TabList>
                                    <Tab>Data Plot</Tab>
                                    <Tab>Data Readout</Tab>
                                </TabList>
                                <TabPanel>
                                    <h3>Data Plot</h3>
                                    <ResponsiveContainer width="100%" aspect={2}>
                                        <LineChart data={vitalSignsData} >
                                            <Legend verticalAlign='top' height={36}/>
                                            <CartesianGrid />
                                            <XAxis dataKey="timestamp" angle={90} height={200} textAnchor='start' interval={"preserveStartEnd"} />
                                            <YAxis></YAxis>
                                            <Tooltip />
                                            <Line
                                                name="Skin Temperature in Celsius"
                                                dataKey="skin_temp"
                                                stroke="black"
                                                activeDot={{ r: 8 }}
                                            />
                                            <Line 
                                                name="Heart Rate in beats per minute"
                                                dataKey="heart_rate" 
                                                stroke="red" 
                                                activeDot={{ r: 8 }} 
                                            />
                                            <Line 
                                                name="Heart Rate Variability"
                                                dataKey="heart_rate_variability" 
                                                stroke="blue" 
                                                activeDot={{ r: 8 }} 
                                            />
                                            <Line 
                                                name="Respiratory Rate"
                                                dataKey="respiratory_rate" 
                                                stroke="green" 
                                                activeDot={{ r: 8 }} 
                                            />
                                            <Line 
                                                name="Blood Oxygen Saturation"
                                                dataKey="blood_oxygen_saturation" 
                                                stroke="purple" 
                                                activeDot={{ r: 8 }} 
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </TabPanel>
                                <TabPanel>
                                    <h3>Data Readout</h3>
                                    <BootstrapTable
                                        keyField="time" // Unique identifier for each data point
                                        data={vitalSignsData}
                                        columns={[
                                        {
                                            dataField: 'time',
                                            text: 'Timestamp',
                                            sort: true,
                                        },
                                        {
                                            dataField: 'skin_temp',
                                            text: 'Skin Temperature in Celsius',
                                            sort: true,
                                        },
                                        {
                                            dataField: 'heart_rate',
                                            text: 'Heart Rate in beats per minute',
                                            sort: true,
                                        },
                                        {
                                            dataField: 'heart_rate_variability',
                                            text: 'Heart Rate Variability',
                                            sort: true,
                                        },
                                        {
                                            dataField: 'respiratory_rate',
                                            text: 'Respiratory Rate',
                                            sort: true,
                                        },
                                        {
                                            dataField: 'blood_oxygen_saturation',
                                            text: 'Blood Oxygen Saturation',
                                            sort: true,
                                        },
                                        ]}
                                    />
                                </TabPanel>
                            </Tabs>
                        </div>
                        <br/>
                        <br/>
                        {/* <div className='emergencyBtnDiv'>
                            <button className='EmergencyBtn' onClick={handleEmergencyClick} disabled={aptStatus === "Not Started"}>
                                EMERGENCY, Notify First Responders
                            </button>
                        </div> */}
                    </div>
                </main>
                {showDelPopup && (
                    <div className="popup">
                        <div className="content">
                            <h3>Are you sure?</h3>
                            <p>
                                Please confirm you would like to delete appointment:
                            </p>
                            <table className="aptInfoTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label htmlFor="rapid-id">RAPID ID:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.rapid_id_assigned_to}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="aptStatus">Appointment Status:</label>
                                        </td>
                                        <td>
                                            <label>{aptStatus}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                        </td>
                                        <td>
                                            <label>{new Date(currentAppt.scheduled_start).toLocaleString()}</label>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <label htmlFor="drug">Drug:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.drug}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="pump-status">Pump Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[pumpStatus]   
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="monitor-status">Monitor Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Warning':
                                                        <div className="warningStatusIndicator">
                                                            Warning
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[monitorStatus]   
                                            }
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <label htmlFor="infusionDose">Dose rate:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.infusion_dose}</label>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="Current-Step-Count">Current Step Count:</label>
                                        </td>
                                        <td>
                                            {lastStepCount && (
                                                <label>{new Date(lastStepCount.time).toLocaleString()} - {lastStepCount.step_count}</label>
                                            )}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>
                                            <label htmlFor="timeIncrement">Time Increment:</label>
                                        </td>
                                        <td>
                                            <label>{currentAppt.time_increment} </label>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <button type="button" onClick={handlePopupDelete}>Delete</button>
                            <button type="button" onClick={handlePopupCancel}>Cancel</button>
                        </div>
                    </div>
                )}
                {/* {showEmergencyPopup && (
                    <div className="popup">
                        <div className="content">
                            <h3>CONFIRM EMERGENCY</h3>
                            <p>
                                Please confirm that an emergency is occurring. This will send a stop response to the pump
                                and notify first responders.
                            </p>
                            <button type="button" onClick={handleEmergencyPopupConfirm}>CONFIRM EMERGENCY</button>
                            <button type="button" onClick={handleEmergencyPopupCancel}>Cancel</button>
                        </div>
                    </div>
                )}
                {showStartPopup && (
                    <div className="popup">
                        <div className="content">
                            <h3>Start Injection?</h3>
                            <p>
                                The patient will get notified that you want to start the injection treatment with the following parameters:
                            </p>
                            <table className="aptInfoTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label htmlFor="rapid-id">RAPID ID:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.rapid_id_assigned_to}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="aptStatus">Appointment Status:</label>
                                        </td>
                                        <td>
                                            <label>{aptStatus}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.scheduled_start}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="drug">Drug:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.drug}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="pump-status">Pump Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[pumpStatus]   
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="monitor-status">Monitor Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Warning':
                                                        <div className="warningStatusIndicator">
                                                            Warning
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[monitorStatus]   
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="infusionDose">Infusion Dose:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.infusion_dose} mg</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="stepCount">Step Count:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.step_count} </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="timeIncrement">Time Increment:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.time_increment} </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button type="button" onClick={handleStartInjection}>Start Injection</button>
                            <button type="button" onClick={handleCancelInjectionStart}>Cancel</button>
                        </div>
                    </div>
                )}
                {showStopPopup && (
                    <div className="popup">
                        <div className="content">
                            <h3>Stop Injection?</h3>
                            <p>
                                Please confirm that you would like to stop the injection treatment before completion.
                            </p>
                            <button type="button" onClick={handleStopInjection}>Yes, I want to cancel the injection</button>
                            <button type="button" onClick={handleCancelStopInjection}>No, I do not want to cancel the injection</button>
                        </div>
                    </div>
                )}
                {startCountdownActive && (
                    <div className="popup">
                        <div className="content">
                            <h3>Injection Start Countdown</h3>
                            <p>
                                The injection will start in :
                            </p>
                            <Countdown 
                                className='countDown' 
                                date={Date.now() + 10000} 
                                onComplete={injectionStart}
                                renderer={
                                    ({ hours, minutes, seconds, completed }) => {
                                        // Render a countdown
                                        if(!completed){
                                            return <h1>{seconds} seconds</h1>;
                                        }
                                        else{
                                            return null;
                                        }
                                    }
                                }
                            />
                            <button type="button" onClick={handleCountdownCancel}>Cancel</button>
                            <h3>Appointment Details:</h3>
                            <table className="aptInfoTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label htmlFor="rapid-id">RAPID ID:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.rapid_id_assigned_to}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="aptStatus">Appointment Status:</label>
                                        </td>
                                        <td>
                                            <label>{aptStatus}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.scheduled_start}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="drug">Drug:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.drug}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="pump-status">Pump Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[pumpStatus]   
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="monitor-status">Monitor Status:</label>
                                        </td>
                                        <td>
                                            {
                                                {
                                                    'Disconnected': 
                                                        <div className="disconnectedStatusIndicator">
                                                            Disconnected
                                                        </div>,
                                                    'Warning':
                                                        <div className="warningStatusIndicator">
                                                            Warning
                                                        </div>,
                                                    'Connected':
                                                        <div className="connectedStatusIndicator">
                                                            Connected
                                                        </div>
                                                }[monitorStatus]   
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="infusionDose">Infusion Dose:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.infusion_dose} mg</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="stepCount">Step Count:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.step_count} </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="timeIncrement">Time Increment:</label>
                                        </td>
                                        <td>
                                            <label>{selectedApt.time_increment} </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )} */}
            </div>
        );
    }
};

export default AptView;